import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import TodoModal from "@/components/TodoModal/index.jsx";
import { useState } from "react";

const TodoButton = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        className="bg-primary w-[142px] text-[16px] h-[48px] leading-[24px] mx-[20px]"
        onClick={() => setIsModalVisible(true)}
      >
        Add a To-do
      </Button>
      <TodoModal
        isTodoModalVisible={isModalVisible}
        onSubmit={() => setIsModalVisible(false)}
      />
    </>
  );
};

export default TodoButton;
