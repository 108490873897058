import { Avatar, Button, Col, Input, message, Modal, Row, Typography } from "antd";
import Jira from "@/assets/jira.svg";
import { useState } from "react";
import { BranchesOutlined, LinkOutlined, MailOutlined } from "@ant-design/icons";
import useLoading from "@/hooks/useLoading.js";
import insightServices from "@/services/apiServices/insightServices/index.js";
import useUser from "@/store/useUser.js";
import LinkOff from "@/assets/link_off.svg";
import useLocalStorage from "@/hooks/useLocalStorage.js";

const ConnectJiraApp = () => {
  const [modalType, setModalType] = useState("");
  const { data: userData, mutate: mutateUserData } = useUser();
  const [, setIsJiraAppVisible] = useLocalStorage("Jira", false);
  const [jiraProject, setJiraProject] = useState({
    jira_email: null,
    jira_auth_token: null,
    jira_url: null,
  });

  const user = userData?.data;

  const { executeAction: handleJiraConnect, loading: isJiraAppConnectLoading } =
    useLoading(async () => {
      try {
        await insightServices.connectJira(jiraProject);
        await mutateUserData();
        message.success("Jira app connected successfully.");
      } catch (e) {
        message.error(
          typeof e?.response?.data?.error === typeof String()
            ? e.response.data.error
            : "Something went wrong. Please try again in some time.",
        );
      } finally {
        setModalType("");
        setJiraProject({
          jira_email: null,
          jira_auth_token: null,
          jira_url: null,
        });
      }
    });
  const { executeAction: handleJiraDisconnect, loading: isJiraAppDisconnectLoading } =
    useLoading(async () => {
      try {
        await insightServices?.disconnectJira();
        await mutateUserData();
        message.success("Jira app disconnected successfully.");
        setIsJiraAppVisible(false);
      } catch (e) {
        message.error(
          typeof e?.response?.data?.error === typeof String()
            ? e.response.data.error
            : "Something went wrong. Please try again in some time.",
        );
      } finally {
        setModalType("");
      }
    });

  return (
    <div>
      <Row className="items-center justify-between">
        <Row>
          <div className="mr-[20px]">
            <Jira className="h-[48px] w-[48px]" />
          </div>
          <Row className="flex flex-col">
            <Typography.Text className="text-[14px] leading-[22px] font-semibold">
              Jira app
            </Typography.Text>
            <Typography.Text className="text-[14px] text-[#606369] leading-[22px] font-normal">
              Connect accounts and sync your Jira entries and tasks with Finitetime
            </Typography.Text>
          </Row>
        </Row>
        <Button
          onClick={() => {
            if (!user?.is_Jira_connected) setModalType("Jira");
            else setModalType("Jira_Disconnect");
          }}
        >
          {!user?.is_Jira_connected ? "Connect" : "Disconnect"}
        </Button>
      </Row>
      <Modal
        title="Connecting apps"
        open={modalType === "Jira"}
        onCancel={() => {
          setModalType("");
        }}
        onOk={() => {}}
        centered
        footer={[]}
        width={550}
        destroyOnClose
      >
        <Row className="flex-col">
          <Row className="items-center justify-center mb-[20px]">
            <Avatar className="w-[48px] h-[48px] mr-[30px] flex items-center justify-center bg-primary text-3xl">
              {user?.email?.[0]?.toUpperCase()}
            </Avatar>
            <LinkOutlined className="text-[22px]" />
            <div className="ml-[30px]">
              <Jira className="h-[48px] w-[48px]" />
            </div>
          </Row>
          <Typography.Text className="text-[16px] mb-[10px] font-medium leading-[22px]">
            Connect accounts and sync your Jira with Finitetime
          </Typography.Text>
          <Row className="items-center mt-[8px] mb-[20px]">
            <Col className="w-full">
              <Row className="mb-[20px] items-center justify-between">
                <Row className="items-center">
                  <MailOutlined className="mr-[16px]" />
                  <Typography.Text className="text-[14px] font-normal leading-[24px]">
                    Jira Email
                  </Typography.Text>
                </Row>
                <Input
                  className="w-[250px] mr-[60px]"
                  placeholder="Jira Email"
                  value={jiraProject?.jira_email}
                  onChange={(e) =>
                    setJiraProject({
                      ...jiraProject,
                      jira_email: e.target.value,
                    })
                  }
                />
              </Row>
              <Row className="mb-[20px] items-center justify-between">
                <Row className="items-center">
                  <BranchesOutlined className="mr-[16px]" />
                  <Typography.Text className="text-[14px] font-normal leading-[24px]">
                    Jira Auth Token
                  </Typography.Text>
                </Row>
                <Input
                  className="w-[250px] mr-[60px]"
                  placeholder="Token"
                  value={jiraProject?.jira_auth_token}
                  onChange={(e) =>
                    setJiraProject({
                      ...jiraProject,
                      jira_auth_token: e.target.value,
                    })
                  }
                />
              </Row>
              <Row className="mb-[20px] items-center justify-between">
                <Row className="items-center">
                  <LinkOutlined className="mr-[16px] font-bold" />
                  <Typography.Text className="text-[14px] font-normal leading-[24px]">
                    Jira URL
                  </Typography.Text>
                </Row>
                <Input
                  className="w-[250px] mr-[60px]"
                  value={jiraProject?.jira_url}
                  placeholder={"https://company.atlassian.net/"}
                  onChange={(e) =>
                    setJiraProject({
                      ...jiraProject,
                      jira_url: e.target.value,
                    })
                  }
                />
              </Row>
            </Col>
          </Row>
          <Typography.Text className="text-[#606369] text-[14px] leading-[22px] mb-[20px]">
            By connecting to Jira, App can access information about related Jira activity.
            They may also share info with FiniteTime about your Jira account and tasks.
          </Typography.Text>
          <Typography.Text className="text-[#606369] text-[14px] leading-[22px]">
            You can disconnect anytime in your Finitetime settings.
          </Typography.Text>
          <div className="flex w-full flex-row-reverse">
            <Button
              type="primary"
              className="bg-primaryDark ml-[8px]"
              onClick={handleJiraConnect}
              loading={isJiraAppConnectLoading}
            >
              Connect
            </Button>
          </div>
        </Row>
      </Modal>
      <Modal
        title="Connecting apps"
        open={modalType === "Jira_Disconnect"}
        onCancel={() => {
          setModalType("");
        }}
        onOk={() => {}}
        centered
        footer={[
          <Button onClick={() => setModalType("")} key="cancel">
            Cancel
          </Button>,
          <Button
            type="primary"
            danger={true}
            onClick={handleJiraDisconnect}
            key="disconnect"
            loading={isJiraAppDisconnectLoading}
          >
            Disconnect
          </Button>,
        ]}
        width={650}
        destroyOnClose
      >
        <Row className="flex-col">
          <Row className="items-center justify-center mb-[20px]">
            <Avatar className="w-[48px] h-[48px] mr-[30px] flex items-center justify-center bg-primary text-3xl">
              {user?.email?.[0]?.toUpperCase()}
            </Avatar>
            <LinkOff className="text-[22px]" />
            <div className="ml-[30px]">
              <Jira className="w-[48px] h-[48px]" />
            </div>
          </Row>
          <Typography.Text className="text-[16px] font-medium leading-[22px]">
            Disconnecting Jira will remove all future entries from Jira
          </Typography.Text>
          <Typography.Text className="text-[#606369] text-[14px] leading-[22px] mb-[20px]">
            You will not be able to view Jira entries after disconnecting Jira. It will
            not affect your existing Jira entries.
          </Typography.Text>
          <Typography.Text className="text-[#606369] text-[14px] leading-[22px]">
            You can reconnect anytime in your Finitetime settings.
          </Typography.Text>
        </Row>
      </Modal>
    </div>
  );
};

export default ConnectJiraApp;
