import {
  Button,
  Col,
  Divider,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  TimePicker,
  Typography,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { DeleteOutlined, TagOutlined } from "@ant-design/icons";
import insightServices from "@/services/apiServices/insightServices/index.js";
import Priority from "@/assets/priority.svg";
import useLoading from "@/hooks/useLoading.js";
import eventBus from "@/utils/eventBus.js";
import { EVENT_BUS_EVENTS } from "@/constants/index.js";
import useTimer from "@/store/useTimer.js";
import clsx from "clsx";
import CategoryDropdown from "@/components/CategoryDropdown/index.jsx";
import { defaultPriorities } from "@/mocks/index.js";
import Union from "@/assets/union.svg";
import AlertClip from "@/assets/alert_clip.mp3";
import useIsTabActive from "@/hooks/useIsTabActive.js";
const format = "HH:mm";

var song = new Audio(AlertClip);
var playPromise = undefined;
song.controls = false;
song.muted = true;
song.loop = true;
song.autoplay = true;

const Timer = () => {
  const text = useTimer((store) => store.text);
  const category = useTimer((store) => store.category);
  const priority = useTimer((store) => store.priority);
  const startTime = useTimer((store) => store.startTime);
  const time = useTimer((store) => store.time);
  const endTime = useTimer((store) => store.endTime);
  const isRunning = useTimer((store) => store.isRunning);
  const isTodoTimerRunning = useTimer((store) => store.isTodoTimerRunning);
  const [timerType, setTimerType] = useState("default");
  const [pomodoroTime, setPomodoroTime] = useState(
    dayjs().set("hour", 0).set("minute", 2).set("second", 0),
  );

  const start = useTimer((store) => store.start);
  const stop = useTimer((store) => store.stop);
  const setCategory = useTimer((store) => store.setCategory);
  const setPriority = useTimer((store) => store.setPriority);
  const setText = useTimer((store) => store.setText);

  const subtasks = useTimer((store) => store.subtasks);
  const setSubtasks = useTimer((store) => store.setSubtasks);
  const [subtaskText, setSubtaskText] = useState("");

  const [isTimerModalOpen, setIsTimerModalOpen] = useState(false);
  const [isMoreDetailsOpen, setIsMoreDetailsOpen] = useState(false);
  const isPlaying = useTimer((store) => store.isPlaying);
  const setIsPlaying = useTimer((store) => store.setIsPlaying);

  const isTabVisible = useIsTabActive();

  const zeroPad = (num, places) => String(num)?.padStart(places, "0") || "";

  const resetTimerValues = () => {
    stop();
    setCategory("");
    setPriority("");
    setText("");
    setSubtasks([]);
    setIsTimerModalOpen(false);
    setIsMoreDetailsOpen(false);
    setIsPlaying(false);
  };

  function play() {
    song.muted = false;
    playPromise = song.play();
  }
  function pause() {
    if (playPromise !== undefined) {
      playPromise.then(() => {
        song.pause();
        playPromise = undefined;
      });
    }
  }

  const isTimerAboutToPlay = useMemo(() => {
    return (
      isRunning &&
      time.get("second") === 0 &&
      time.get("minute") === 0 &&
      time.get("hour") === 0
    );
  }, [isRunning, time]);

  useEffect(() => {
    if (timerType === "pomodoro" && isTimerAboutToPlay) {
      if (!isTabVisible && !isPlaying) {
        setIsPlaying(true);
        play();
      }
      if (!isTimerModalOpen) setIsTimerModalOpen(true);
    }
  }, [
    timerType,
    isTimerAboutToPlay,
    isTabVisible,
    isPlaying,
    isTimerModalOpen,
    setIsPlaying,
  ]);

  useEffect(() => {
    if (isTabVisible && isPlaying) {
      pause();
      setIsPlaying(false);
    }
    // eslint-disable-next-line
  }, [isTabVisible, isPlaying]);

  const {
    executeAction: handleCreateTimelineEntry,
    loading: isCreateTimelineEntryLoading,
  } = useLoading(async () => {
    if (!text || text?.length === 0) {
      message.error("Timer title required");
      return;
    }
    try {
      const payload = {
        summary: text,
        start_time: dayjs(startTime).format("YYYY-MM-DDTHH:mm:ss[Z]"),
        end_time: dayjs(endTime).format("YYYY-MM-DDTHH:mm:ss[Z]"),
        category: category,
        priority: priority || "unassigned",
        subtasks:
          subtasks?.map((task) => ({
            ...task,
            start_time: task.start_time ? dayjs(task.start_time)?.format() : "",
            end_time: task.end_time ? dayjs(task.end_time)?.format() : "",
          })) || [],
      };
      await insightServices.createTimeline(payload);
      eventBus.emit(EVENT_BUS_EVENTS.REVALIDATE_TIMELINE_EVENTS, {});
      message.success("Timer event created Successfully.");
    } catch (error) {
      message.error(
        typeof error?.response?.data?.msg === "string"
          ? error?.response?.data?.msg
          : "Something went wrong. Please try again in some time.",
      );
    } finally {
      resetTimerValues();
    }
  });

  const handleTimerModalSave = async () => {
    if (!text) {
      message.info("Title required");
      return;
    }
    if (isRunning) {
      await handleCreateTimelineEntry();
      return;
    }
    if (timerType === "pomodoro") {
      const pomodoroTimeMinutes =
        pomodoroTime.get("hours") * 60 + pomodoroTime.get("minutes");
      const newTime = dayjs().set(
        "minutes",
        dayjs().get("minutes") + pomodoroTimeMinutes,
      );
      start(newTime);
    } else start();
    setIsTimerModalOpen(false);
    setIsMoreDetailsOpen(false);
    setIsPlaying(false);
  };
  const handleDiscardTimer = () => {
    resetTimerValues();
  };

  const handleAddSubtask = () => {
    if (!subtaskText) {
      message.info("Name required");
      return;
    }
    const currentSubtasks = [...subtasks];
    const currentText = subtaskText;
    currentSubtasks.push({
      title: currentText,
      start_time: "",
      end_time: "",
      id: new Date().getTime(),
    });
    setSubtasks(currentSubtasks);
    setSubtaskText("");
  };

  const handleDeleteSubtask = (id) => {
    let currentSubtasks = [...subtasks];
    currentSubtasks = currentSubtasks.filter((task) => task.id !== id);
    setSubtasks(currentSubtasks);
  };

  const handleTaskTimeChange = (id, time) => {
    let currentSubtasks = [...subtasks];
    const [startTime, endTime] = time;
    currentSubtasks = currentSubtasks.map((task) => {
      if (task.id == id) {
        return {
          ...task,
          start_time: dayjs(startTime),
          end_time: dayjs(endTime),
        };
      }
      return task;
    });
    setSubtasks(currentSubtasks);
  };

  const handleSubtaskStart = (id) => {
    let currentSubtasks = [...subtasks];
    currentSubtasks = currentSubtasks.map((task) => {
      if (task.id == id) {
        return {
          ...task,
          start_time: dayjs(),
        };
      }
      return task;
    });
    setSubtasks(currentSubtasks);
  };

  const handleSubtaskStop = (id) => {
    let currentSubtasks = [...subtasks];
    currentSubtasks = currentSubtasks.map((task) => {
      if (task.id == id) {
        return {
          ...task,
          end_time: dayjs(),
        };
      }
      return task;
    });
    setSubtasks(currentSubtasks);
  };

  const getDefaultTime = (task) => {
    let time = [];
    if (task.start_time) time.push(dayjs(task.start_time, format));
    if (task.end_time) time.push(dayjs(task.end_time, format));
    return time;
  };

  const handleExtendTime = (minutes) => {
    const currentTime = dayjs()
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .set("minutes", Number(minutes));
    setPomodoroTime(currentTime);
  };

  const handleTimeSelect = (time) => {
    setPomodoroTime(time);
  };

  const extendTimer = () => {
    const currentTime = dayjs().set("minutes", dayjs().get("minutes") + 2);
    start(currentTime);
    setIsTimerModalOpen(false);
    setIsMoreDetailsOpen(false);
    setIsPlaying(false);
  };

  return (
    <>
      <Row className="flex-col mx-[20px] my-[40px]">
        <Radio.Group
          onChange={(e) => setTimerType(e.target.value)}
          value={timerType}
          className="flex mb-[20px]"
          disabled={isRunning}
        >
          <Radio.Button value="default">Timer</Radio.Button>
          <Radio.Button value="pomodoro">Pomodoro Timer</Radio.Button>
        </Radio.Group>
        {timerType === "pomodoro" && !isRunning && (
          <>
            <Row className="items-center my-[10px]">
              <Typography.Text className="mr-2 text-[#606369]">Custom</Typography.Text>
              <TimePicker
                format={format}
                value={dayjs(pomodoroTime, format)}
                onChange={(value) => handleTimeSelect(value)}
              />
            </Row>
            <Row className="items-center mb-[20px]">
              <Button className="rounded-2xl mr-2" onClick={() => handleExtendTime(5)}>
                5 m
              </Button>
              <Button className="rounded-2xl mr-2" onClick={() => handleExtendTime(15)}>
                15 m
              </Button>
              <Button className="rounded-2xl mr-2" onClick={() => handleExtendTime(25)}>
                25 m
              </Button>
            </Row>
          </>
        )}
        {(isRunning || timerType !== "pomodoro") && (
          <>
            <Typography.Text className="text-[#606369] text-[14px] font-normal mb-[8px] tracking-[0.1px]">
              {!isRunning ? " No active timer" : `Timer ${text} running`}
            </Typography.Text>
            <Row className="mb-[20px]">
              <Typography.Text className="font-medium tracking-[0.1px] mr-1">
                <Typography.Text className="text-primary text-[24px]">
                  {zeroPad(dayjs(time)?.get("hour"), 2)}
                </Typography.Text>
                <Typography.Text className="text-primary text-[12px]">hr</Typography.Text>
              </Typography.Text>
              <Typography.Text className="font-medium tracking-[0.1px] mr-1">
                <Typography.Text className="text-primary text-[24px]">
                  {zeroPad(dayjs(time)?.get("minute"), 2)}
                </Typography.Text>
                <Typography.Text className="text-primary text-[12px]">
                  min
                </Typography.Text>
              </Typography.Text>
              <Typography.Text className="font-medium tracking-[0.1px]">
                <Typography.Text className="text-primary text-[24px]">
                  {zeroPad(dayjs(time)?.get("second"), 2)}
                </Typography.Text>
                <Typography.Text className=" text-primary text-[12px]">
                  sec
                </Typography.Text>
              </Typography.Text>
            </Row>
          </>
        )}
        <Row>
          {!isTodoTimerRunning && (
            <Button
              type="primary"
              className="bg-primary mr-[8px]"
              onClick={() => {
                setIsTimerModalOpen(true);
              }}
            >
              {!isRunning ? "Start" : "Update"}
            </Button>
          )}
        </Row>
        <Modal
          getContainer={document.getElementById("root")}
          destroyOnClose
          title={isRunning ? "Edit Timer" : "New Timer"}
          open={isTimerModalOpen}
          closeIcon={false}
          onCancel={() => {
            setIsTimerModalOpen(false);
            setIsMoreDetailsOpen(false);
            if (!isRunning) resetTimerValues();
          }}
          footer={[
            <Row className="w-full justify-between" key="footer">
              {!isMoreDetailsOpen && (
                <Button
                  type="text"
                  className="text-[#1890FF] p-0 m-0"
                  onClick={() => setIsMoreDetailsOpen(true)}
                >
                  Add more details
                </Button>
              )}
              <Row
                className={clsx("justify-end", {
                  "w-full": isMoreDetailsOpen,
                })}
              >
                {isRunning && timerType === "pomodoro" && (
                  <Button onClick={extendTimer}>Extend</Button>
                )}
                {isRunning && <Button onClick={handleDiscardTimer}>Delete</Button>}
                <Button
                  type="primary"
                  className="bg-primary"
                  onClick={handleTimerModalSave}
                  loading={isCreateTimelineEntryLoading && isRunning}
                >
                  {isRunning ? "Stop" : "Save"}
                </Button>
              </Row>
            </Row>,
          ]}
          centered
          width={500}
        >
          <>
            <Input
              placeholder="Add title"
              bordered={false}
              size="large"
              autoFocus
              value={text}
              onKeyDown={(e) => e.key === "Enter" && handleTimerModalSave()}
              className="text-[24px] m-0 p-0 w-full"
              onChange={(e) => setText(e.target.value)}
            />
            {isMoreDetailsOpen && (
              <Row className="flex-col mt-[16px]">
                <Row className="mb-[16px] items-center justify-between">
                  <Row className="items-center">
                    <TagOutlined className="mr-[16px]" />
                    <Typography.Text className="text-[14px] font-normal leading-[24px]">
                      Category{" "}
                    </Typography.Text>
                  </Row>
                  <div className="w-[314px]">
                    <CategoryDropdown
                      isNewCategoryAllowed={true}
                      onSelectOption={(parentArrayKeys) =>
                        setCategory(parentArrayKeys?.[parentArrayKeys.length - 1])
                      }
                    />
                  </div>
                </Row>
                <Row className="mb-[4px] items-center justify-between">
                  <Row className="items-center">
                    <Priority className="mr-[16px]" />
                    <Typography.Text className="text-[14px] font-normal leading-[24px]">
                      Priority{" "}
                    </Typography.Text>
                  </Row>
                  <Select
                    showSearch
                    clearIcon
                    className="w-[314px]"
                    placeholder="Select Priorities"
                    value={priority}
                    onSelect={(value) => setPriority(value)}
                  >
                    {defaultPriorities?.map((priority) => (
                      <Select.Option
                        value={priority.key}
                        key={`${priority.key} Priority`}
                      >
                        {priority.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Row>
                <Divider className="my-[20px]" />
                <Row className="flex-col w-full">
                  <Row className="items-center mb-[20px]">
                    <Union className="fill-black" />
                    <Typography.Text className="ml-2">Subtask</Typography.Text>
                  </Row>
                  {subtasks?.map((task, index) => (
                    <Row key={task.id} className="items-center justify-between mb-[10px]">
                      <Row className="items-center">
                        <div
                          className="h-[16px] w-[16px] flex rounded justify-center items-center text-center text-[10px] mr-2  text-[#606369]"
                          style={{
                            borderRadius: "32px",
                            border:
                              "1px solid var(--character-disabled-placeholder-25, rgba(0, 0, 0, 0.25))",
                          }}
                        >
                          {index + 1}
                        </div>
                        <Typography.Text className="text-[14px] leading-[22px]">
                          {task.title}
                        </Typography.Text>
                      </Row>
                      <Row className="items-center">
                        <TimePicker.RangePicker
                          format={format}
                          value={getDefaultTime(task)}
                          onChange={(value) => handleTaskTimeChange(task.id, value)}
                          className="mr-4"
                        />
                        {!task.start_time && (
                          <Button
                            type="primary"
                            className="bg-primary"
                            onClick={() => handleSubtaskStart(task.id)}
                          >
                            Start
                          </Button>
                        )}
                        {task.start_time && !task.end_time && (
                          <Button
                            type="primary"
                            className="bg-primary"
                            onClick={() => handleSubtaskStop(task.id)}
                          >
                            Stop
                          </Button>
                        )}
                        <DeleteOutlined
                          className="ml-4"
                          onClick={() => handleDeleteSubtask(task.id)}
                        />
                      </Row>
                    </Row>
                  ))}
                </Row>
                <Row className="justify-between w-full my-[10px]">
                  <Col span={16}>
                    <Input
                      value={subtaskText}
                      placeholder="Type and hit enter to add new subtask"
                      onChange={(e) => setSubtaskText(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleAddSubtask();
                        }
                      }}
                    />
                  </Col>
                  <Col span={4} className="flex flex-row-reverse">
                    <Button
                      onClick={handleAddSubtask}
                      type="primary"
                      className="bg-primary ml-2"
                    >
                      Add new subtask
                    </Button>
                  </Col>
                </Row>
              </Row>
            )}
          </>
        </Modal>
      </Row>
    </>
  );
};

export default Timer;
