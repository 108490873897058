import { Button, Input, message, Modal, Radio, Row, Typography } from "antd";
import { ExclamationCircleOutlined, TagOutlined } from "@ant-design/icons";
import CategoryDropdown from "@/components/CategoryDropdown/index.jsx";
import Stroke from "@/assets/stroke.svg";
import { useEffect, useState } from "react";
import useLoading from "@/hooks/useLoading.js";
import insightServices from "@/services/apiServices/insightServices/index.js";
import useSubcategory from "@/store/useSubcategory.js";
import ColorDropDown from "@/components/ColorDropDown/index.jsx";

const CategoryModal = ({
  isModalVisible,
  onClose,
  defaultCategory,
  defaultParentCategory,
  onCategoryCreation,
}) => {
  const { mutate } = useSubcategory();
  const [categoryType, setCategoryType] = useState("default");
  const [categoryText, setCategoryText] = useState(
    defaultCategory?.isEditCategory ? defaultCategory?.name : "",
  );
  const [selectedColor, setSelectedColor] = useState(defaultCategory?.color || "");
  const [parentId, setParentId] = useState(defaultCategory?.parent);
  const [isDeleteCategoryModalId, setIsDeleteCategoryModalId] = useState();
  const [replaceCategoryId, setReplaceCategoryId] = useState(false);

  useEffect(() => {
    if (defaultCategory?.isEditCategory) {
      setCategoryText(defaultCategory?.name);
      setParentId(defaultCategory?.parent);
    } else setParentId(defaultCategory?.id);
    setSelectedColor(defaultCategory?.color);
  }, [defaultCategory]);

  const resetModal = () => {
    setCategoryType("default");
    setCategoryText("");
    setSelectedColor("");
    setParentId();
  };

  useEffect(() => {
    return () => {
      resetModal();
    };
  }, []);

  const { executeAction: handleCreateCategory, loading: isCreateCategoryLoading } =
    useLoading(async () => {
      if ((categoryType === "default" && !parentId) || !selectedColor || !categoryText) {
        message.error("Please fill the details");
        return;
      }
      try {
        const payload = {
          name: categoryText,
          color: selectedColor,
          parent: categoryType === "parent" ? undefined : parentId,
        };
        const data = await insightServices.createCategory(payload);
        onCategoryCreation?.(data);
        await mutate();
        message.success("Category created successfully.");
      } catch (e) {
        message.error(
          typeof e?.response?.data?.msg === typeof String()
            ? e.response.data.msg
            : "Something went wrong. Please try again in some time.",
        );
      } finally {
        resetModal();
        onClose();
      }
    });

  const { executeAction: handleDeleteCategory, loading: isDeleteCategoryLoading } =
    useLoading(async () => {
      try {
        await insightServices.removeCategory(defaultCategory?.id);
        await mutate();
        message.success("Category deleted successfully.");
      } catch (e) {
        message.error(
          typeof e?.response?.data?.msg === typeof String()
            ? e.response.data.msg
            : "Something went wrong. Please try again in some time.",
        );
        if (
          typeof e?.response?.data?.msg === typeof String() &&
          e.response.data.msg ===
            "Category (or its subcategory) is attached to few events so it cannot be deleted"
        ) {
          setIsDeleteCategoryModalId(defaultCategory?.id);
        }
      } finally {
        resetModal();
        onClose();
      }
    });

  const { executeAction: handleUpdateCategory, loading: isUpdateCategoryLoading } =
    useLoading(async () => {
      if (!selectedColor || !categoryText) {
        message.error("Please fill the details");
        return;
      }
      try {
        const payload = {
          id: defaultCategory?.id,
          name: categoryText,
          color: selectedColor,
          parent: categoryType === "parent" ? undefined : parentId,
        };
        await insightServices.updateCategory(payload);
        await mutate();
        message.success("Category updated successfully.");
      } catch (e) {
        message.error(
          typeof e?.response?.data?.msg === typeof String()
            ? e.response.data.msg
            : "Something went wrong. Please try again in some time.",
        );
      } finally {
        resetModal();
        onClose();
      }
    });

  const { executeAction: handleReplaceCategory, loading: isReplaceCategoryLoading } =
    useLoading(async () => {
      try {
        const payload = {
          replaceCategoryId: replaceCategoryId,
          deleteCategoryId: isDeleteCategoryModalId,
        };
        await insightServices.replaceCategory(payload);
        await mutate();
        message.success("Category updated successfully.");
      } catch (e) {
        message.error(
          typeof e?.response?.data?.msg === typeof String()
            ? e.response.data.msg
            : "Something went wrong. Please try again in some time.",
        );
      } finally {
        setIsDeleteCategoryModalId();
        resetModal();
        onClose();
      }
    });

  return (
    <>
      <Modal
        getContainer={document.getElementById("root")}
        destroyOnClose
        title={defaultCategory?.isEditCategory ? "Edit category" : "Add new category"}
        open={isModalVisible}
        onCancel={() => {
          onClose();
          setSelectedColor("");
          setCategoryText("");
        }}
        footer={[
          <Button
            key="back"
            onClick={handleDeleteCategory}
            loading={isDeleteCategoryLoading}
          >
            Delete
          </Button>,
          <Button
            key="submit"
            type="primary"
            className="bg-primary"
            onClick={
              defaultCategory?.isEditCategory
                ? handleUpdateCategory
                : handleCreateCategory
            }
            loading={isCreateCategoryLoading || isUpdateCategoryLoading}
          >
            Save
          </Button>,
        ]}
        onOk={() => {}}
        centered
        width={650}
      >
        <Input
          placeholder="Add title"
          bordered={false}
          size="large"
          autoFocus
          value={categoryText}
          className="text-[24px] m-0 p-0"
          onChange={(e) => setCategoryText(e.target.value)}
        />
        {!defaultCategory?.isEditCategory && (
          <>
            <Radio.Group
              onChange={(e) => setCategoryType(e.target.value)}
              value={categoryType}
              className="flex mt-[10px]"
            >
              <Radio.Button value="default">
                Nest it under an existing category
              </Radio.Button>
              <Radio.Button value="parent">Make it a top level category</Radio.Button>
            </Radio.Group>
          </>
        )}
        <Row className="flex-col mt-[20px]">
          <Row className="mb-[20px] items-center justify-between">
            <Row className="items-center">
              <TagOutlined className="mr-[16px]" />
              <Typography.Text className="text-[14px] font-normal leading-[24px]">
                Parent Category{" "}
              </Typography.Text>
            </Row>
            <div className="w-[444px]">
              <CategoryDropdown
                defaultValue={defaultParentCategory}
                disabled={categoryType === "parent" && !defaultCategory?.isEditCategory}
                onSelectOption={(parentArrayKeys) => {
                  setParentId(parentArrayKeys?.[parentArrayKeys?.length - 1]);
                }}
              />
            </div>
          </Row>
          <Row className="mb-[20px] items-center justify-between">
            <Row className="items-center">
              <Stroke className="mr-[16px]" />
              <Typography.Text className="text-[14px] font-normal leading-[24px]">
                Color{" "}
              </Typography.Text>
            </Row>
            <ColorDropDown
              defaultColor={selectedColor}
              onChange={(color) => setSelectedColor(color)}
              width={444}
            />
          </Row>
        </Row>
      </Modal>
      <Modal
        getContainer={document.getElementById("root")}
        destroyOnClose
        title={"Deleting Category"}
        open={isDeleteCategoryModalId}
        width={650}
        onCancel={() => {
          onClose();
          setIsDeleteCategoryModalId();
          setSelectedColor("");
          setCategoryText("");
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              onClose();
              setIsDeleteCategoryModalId();
              setSelectedColor("");
              setCategoryText("");
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            danger
            type="primary"
            onClick={handleReplaceCategory}
            loading={isReplaceCategoryLoading}
          >
            Reassign and delete
          </Button>,
        ]}
      >
        <Row className="flex-col">
          <Row className="items-center justify-center mb-[20px]">
            <ExclamationCircleOutlined className="text-[60px]" />
          </Row>
          <Typography.Text className="text-[16px] font-semibold leading-[22px] mb-[10px]">
            Category you are trying to delete has event/s assigned to it
          </Typography.Text>
          <div className="w-full mb-[20px]">
            <CategoryDropdown
              defaultValue={defaultParentCategory}
              disabled={categoryType === "parent" && !defaultCategory?.isEditCategory}
              onSelectOption={(parentArrayKeys) => {
                setReplaceCategoryId(parentArrayKeys?.[parentArrayKeys?.length - 1]);
              }}
            />
          </div>
        </Row>
      </Modal>
    </>
  );
};

export default CategoryModal;
