import { Button, Row, Typography } from "antd";
import { Calendar, dayjsLocalizer, Views } from "react-big-calendar";
import { useEffect, useMemo, useState } from "react";
import useUser from "@/store/useUser.js";
import useSWR from "swr";
import insightServices from "@/services/apiServices/insightServices/index.js";
import dayjs from "dayjs";
import PrevIcon from "@/assets/prev_btn.svg";
import NextIcon from "@/assets/next_btn.svg";
import clsx from "clsx";
import { weekS } from "@/constants/index.js";
import { getCategoryIdMap } from "@/utils/index.js";
import useSubcategory from "@/store/useSubcategory.js";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./SlotCalendarView.css";
import { CloseOutlined } from "@ant-design/icons";
import MultiTask from "@/assets/multi_task.svg";

const localizer = dayjsLocalizer(dayjs);
const SlotCalendarView = ({ selectedSlot, title, zone_id, onCancel, onSlotChange }) => {
  const { data: userData } = useUser();
  const { data: subCategoriesData } = useSubcategory();
  const [selectedDate, setSelectedDate] = useState(dayjs());

  useEffect(() => {
    setSelectedDate(selectedSlot?.start_time ? dayjs(selectedSlot?.start_time) : dayjs());
  }, [selectedSlot]);

  const conflictCalendarIds = useMemo(() => {
    const calendarsValues = [];
    const subcalendarValues = [];
    userData?.data?.calendars?.forEach((calendar) => {
      calendar?.subcalendars?.forEach((subcalendar) => {
        if (subcalendar?.used_for_conflicts === true) {
          subcalendarValues.push(subcalendar?.id);
          if (!calendarsValues.includes(calendar?.id)) calendarsValues.push(calendar?.id);
        }
      });
    });
    return {
      calendarIds: calendarsValues,
      subCalendarIds: subcalendarValues,
    };
  }, [userData]);

  const { data: eventData, isValidating: isEventsLoading } = useSWR(
    conflictCalendarIds
      ? `fetchEventsData/${selectedDate}/${conflictCalendarIds?.calendarIds}/${conflictCalendarIds?.subCalendarIds}`
      : null,
    () =>
      insightServices.getEvents(
        {
          startDate: dayjs(selectedDate).format("YYYY-MM-DD"),
          endDate: dayjs(selectedDate).format("YYYY-MM-DD"),
        },
        conflictCalendarIds,
      ),
  );
  const { data: zoneData, isValidating: isZoneLoading } = useSWR(
    zone_id ? `fetchZonesData/${zone_id}` : null,
    () => insightServices.getZone(zone_id),
  );

  const { defaultDate, views } = useMemo(
    () => ({
      defaultDate: dayjs(selectedDate),
      views: ["day"],
    }),
    [selectedDate],
  );

  const calendarResourceMap = useMemo(() => {
    const resourceMap = [];
    resourceMap.push({
      resourceId: 0,
      resourceTitle: "",
    });
    return resourceMap;
  }, []);

  const calendarEvents = useMemo(() => {
    let events = [];
    const currentCalendarEvents =
      eventData?.data?.events.map((event) => {
        return {
          ...event,
          start: new Date(event?.start_time),
          end: new Date(event?.end_time),
          title:
            event?.type === "task_block" ? (
              <span className="w-full flex items-center">
                {event.summary +
                  " (" +
                  event.free_duration_in_mins +
                  "/" +
                  event.duration_in_mins +
                  " min available )"}
                <MultiTask className="ml-2 fill-white" />
              </span>
            ) : (
              event.summary
            ),
          resourceId: [0],
        };
      }) || [];
    events.push(...currentCalendarEvents);
    if (
      selectedSlot &&
      selectedDate?.get("date") === dayjs(selectedSlot?.start_time)?.get("date")
    ) {
      let index = -1;
      events?.forEach((e, i) => {
        if (
          e?.start &&
          e?.end &&
          selectedSlot?.start_time &&
          selectedSlot?.end_time &&
          new Date(e?.start).getTime() === new Date(selectedSlot?.start_time).getTime() &&
          new Date(e?.end).getTime() === new Date(selectedSlot?.end_time).getTime()
        ) {
          index = i;
        }
      });
      if (index < 0) {
        events?.push({
          start: new Date(selectedSlot?.start_time),
          end: new Date(selectedSlot?.end_time),
          title: title,
          resourceId: [0],
        });
      }
    }
    return events;
    // eslint-disable-next-line
  }, [eventData, selectedDate, selectedSlot, title, zone_id]);

  const categoryIdMap = useMemo(
    () => getCategoryIdMap(subCategoriesData?.data?.categories, {}),
    [subCategoriesData],
  );

  const eventStyleGetter = (event) => {
    const backgroundColor = categoryIdMap?.[event?.category_id]?.color
      ? categoryIdMap?.[event?.category_id]?.color
      : "#9ab6c9";

    const style = {
      backgroundColor: backgroundColor,
      borderRadius: "2px",
      color: "white",
      padding: "10px",
      border: "1px solid #FFF",
      fontSize: "14px",
      lineHeight: "20px",
      opacity: 1,
      ...(!categoryIdMap?.[event?.category_id]?.color && {
        border: "1px dashed black",
        backgroundColor: "white",
        color: "black",
      }),
      whiteSpace: "nowrap",
      width: "16em",
      overflow: "hidden",
      textOverflow: "ellipsis",
    };
    return {
      style: style,
    };
  };

  const slotPropGetter = (date) => {
    const currentDay = weekS?.[selectedDate?.get("day")];
    const zoneEntries = zoneData?.data?.slots?.[currentDay];
    let style = {};
    zoneEntries?.forEach((zone) => {
      let [shh, smm] = zone.start_time.split(":");
      let [ehh, emm] = zone.end_time.split(":");
      const dayJsStartTime = dayjs(date)
        .set("hours", Number(shh))
        .set("minutes", Number(smm));
      const dayJsEndtime = dayjs(date)
        .set("hours", Number(ehh))
        .set("minutes", Number(emm));

      if (
        new Date(date).getTime() >= new Date(dayJsStartTime).getTime() &&
        new Date(date).getTime() < new Date(dayJsEndtime).getTime()
      ) {
        style = {
          backgroundColor: "black",
          opacity: 0.1,
          color: "white",
        };
      }
    });
    return {
      style: style,
    };
  };
  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber);

    return date.toLocaleString("en-US", {
      month: "long",
    });
  };

  const slotDate = useMemo(() => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const d = new Date(selectedDate);
    const dayName = days[d.getDay()];
    const m = getMonthName(selectedDate.month());
    const dd = selectedDate.date();
    return `${dayName}, ${m} ${dd}`;
  }, [selectedDate]);

  const CalendarToolbar = (props) => {
    const { onNavigate: navigate } = props;
    return (
      <>
        <Row className="items-center justify-between">
          <Typography.Text className="text-[16px] leading-[22px] font-semibold my-[10px]">
            Calendar
          </Typography.Text>
          <Row className="items-center">
            <Button
              type="text"
              className="p-0 m-0"
              icon={<PrevIcon />}
              onClick={() => {
                navigate("PREV");
                setSelectedDate(dayjs(selectedDate).subtract(1, "day"));
              }}
            />
            <Typography.Text className="mx-[12px] text-[14px] font-semibold leading-[20px] mt-1">
              {slotDate}
            </Typography.Text>
            <Button
              type="text"
              className="p-0 m-0"
              icon={<NextIcon />}
              onClick={() => {
                navigate("NEXT");
                setSelectedDate(dayjs(selectedDate).add(1, "day"));
              }}
            />
            <Button
              type="text"
              className="ml-2"
              icon={<CloseOutlined />}
              onClick={onCancel}
            />
          </Row>
        </Row>
      </>
    );
  };

  return (
    <div
      className={clsx("slot flex flex-col relative ml-[20px] h-[91vh]", {
        filtering: isEventsLoading || isZoneLoading,
      })}
      key={`${selectedDate},${selectedSlot?.start_time},${title},${zone_id}`}
    >
      <Calendar
        defaultDate={defaultDate}
        defaultView={Views.DAY}
        events={calendarEvents}
        localizer={localizer}
        resourceIdAccessor="resourceId"
        resources={calendarResourceMap}
        onSelectEvent={() => {}}
        onSelectSlot={(value) => onSlotChange?.(value)}
        resourceTitleAccessor="resourceTitle"
        step={30}
        selectable
        views={views}
        scrollToTime={
          new Date(
            new Date(selectedDate).setHours(
              Number(dayjs(selectedSlot?.start_time).get("hours")) - 3 >= 0
                ? Number(dayjs(selectedSlot?.start_time).get("hours")) - 3
                : 0,
              Number(dayjs(selectedSlot?.start_time).get("minutes")),
            ),
          )
        }
        eventPropGetter={eventStyleGetter}
        slotPropGetter={slotPropGetter}
        components={{
          toolbar: CalendarToolbar,
        }}
      />
    </div>
  );
};

export default SlotCalendarView;
