import { Button, Collapse, Empty, message, Row, Typography } from "antd";
import dayjs from "dayjs";
import { zeroPad } from "@/utils/index.js";
import { useMemo, useState } from "react";
import { clearTimeout } from "worker-timers";
import { PushpinOutlined, SoundOutlined } from "@ant-design/icons";
import useLocalStorage from "@/hooks/useLocalStorage.js";
import SoundOff from "@/assets/sound_off.svg";
import insightServices from "@/services/apiServices/insightServices/index.js";
import useLoading from "@/hooks/useLoading.js";

let id = null;
const NagModalBox = ({ timer, mutateEventReminderData }) => {
  const [updateNagLoadingType, setUpdateNagLoadingType] = useState("");
  const [isMute, setIsMute] = useLocalStorage("audio_clip_sound", false);
  const { isVisible, setIsVisible, time, events, pastEvents, currentEvent, stopAudio } =
    timer;

  const { executeAction: updateNag } = useLoading(async (payload, loadingType) => {
    try {
      setUpdateNagLoadingType(loadingType);
      await insightServices.updateNag(currentEvent?.id, payload);
      if (
        typeof mutateEventReminderData === "function" &&
        payload.action !== "alarm_triggered"
      )
        await mutateEventReminderData?.();
    } catch (e) {
      message.error(
        typeof e?.response?.data?.err === typeof String()
          ? e.response.data.err
          : "Something went wrong. Please try again in some time.",
      );
    } finally {
      setUpdateNagLoadingType("");
      if (payload.action !== "alarm_triggered") {
        if (id) {
          clearTimeout(id);
          id = null;
        }
        stopAudio?.();
      }
    }
  });
  const getTime = (currentTime) => {
    return (
      "(" +
      dayjs(currentTime).format("hh") +
      ":" +
      dayjs(currentTime).format("mm") +
      " " +
      dayjs(currentTime).format("a") +
      ")"
    );
  };

  const handleExtendTime = async (extendTime) => {
    const payload = {
      action: "snooze",
      snooze_by_mins: extendTime,
      actor: "user",
    };
    await updateNag(payload, `extend_by_${extendTime}`);
  };

  const handleClear = async () => {
    const payload = {
      action: "clear",
      actor: "user",
    };
    await updateNag(payload, "clear");
  };

  const upcomingReminders = useMemo(
    () => events?.filter((event) => event?.nag_status === "upcoming") || [],
    [events],
  );

  const nagEvents = useMemo(() => {
    return [
      {
        key: 0,
        label: "Upcoming reminders",
        children: upcomingReminders?.length ? (
          <div className="flex-col p-0">
            {upcomingReminders?.map((event) => (
              <div key={event.id} className="w-full p-[10px]">
                <Typography.Text className="text-[#2A2E36] text-[14px] leading-[22px] mr-1">
                  {getTime(event.nag_time)}
                </Typography.Text>
                <Typography.Text className="text-[#006098] text-[14px] leading-[22px]">
                  {event.summary}
                </Typography.Text>
              </div>
            ))}
          </div>
        ) : (
          <Empty />
        ),
      },
    ];
  }, [upcomingReminders]);

  const getLogs = (event) => {
    return [
      {
        key: 0,
        label: (
          <Row>
            <Typography.Text className="text-[#2A2E36] text-[14px] leading-[22px] mr-1">
              {getTime(event.nag_time)}
            </Typography.Text>
            <Typography.Text className="text-[#006098] text-[14px] leading-[22px]">
              {event.summary}
            </Typography.Text>
          </Row>
        ),
        children: event?.audit_logs?.length ? (
          <div className="flex-col p-0">
            {event?.audit_logs?.map((eventLog, index) => (
              <Row key={index}>
                <Typography.Text className="text-[#2A2E36] text-[14px] leading-[22px] mr-1">
                  {getTime(eventLog.timestamp)}
                </Typography.Text>
                <Typography.Text className="text-[#006098] text-[14px] leading-[22px]">
                  {eventLog.log}
                </Typography.Text>
              </Row>
            ))}
          </div>
        ) : (
          <Empty />
        ),
      },
    ];
  };

  const nagPastEvents = useMemo(() => {
    return [
      {
        key: 0,
        label: "Past reminders logs",
        children: pastEvents.length ? (
          <div className="flex-col p-0">
            {pastEvents.map((event) => (
              <Collapse
                key={event.id}
                ghost
                items={getLogs(event) || []}
                expandIconPosition={"end"}
                className="w-full"
              />
            ))}
          </div>
        ) : (
          <Empty />
        ),
      },
    ];
    // eslint-disable-next-line
  }, [pastEvents]);

  const Timer = (
    <Row>
      <Typography.Text className="font-medium tracking-[0.1px] mr-1">
        <Typography.Text className="text-primary text-[24px]">
          {zeroPad(dayjs(time)?.get("hour"), 2)}
        </Typography.Text>
        <Typography.Text className="text-primary text-[12px]">hr</Typography.Text>
      </Typography.Text>
      <Typography.Text className="font-medium tracking-[0.1px] mr-1">
        <Typography.Text className="text-primary text-[24px]">
          {zeroPad(dayjs(time)?.get("minute"), 2)}
        </Typography.Text>
        <Typography.Text className="text-primary text-[12px]">min</Typography.Text>
      </Typography.Text>
      <Typography.Text className="font-medium tracking-[0.1px]">
        <Typography.Text className="text-primary text-[24px]">
          {zeroPad(dayjs(time)?.get("second"), 2)}
        </Typography.Text>
        <Typography.Text className=" text-primary text-[12px]">sec</Typography.Text>
      </Typography.Text>
    </Row>
  );

  return (
    <div className="w-full">
      <div className="min-w-[500px] overflow-auto flex-col ant-dropdown-menu">
        <Row className="flex-col p-[20px] pb-0">
          <Row className="items-center justify-between">
            <div />
            <Row>
              <Button
                type="text"
                icon={<PushpinOutlined className="h-[20px] w-[20px]" />}
                onClick={() => setIsVisible(true)}
                disabled={isVisible}
              />
              <Button
                type="text"
                icon={
                  isMute ? (
                    <SoundOff className="h-[20px] w-[20px]" />
                  ) : (
                    <SoundOutlined className="h-[20px] w-[20px]" />
                  )
                }
                onClick={() => setIsMute(!isMute)}
              />
            </Row>
          </Row>
          {currentEvent?.nag_time && (
            <>
              <Typography.Text className="text-[#989CA6] text-[14px] leading-[22px]">
                Next reminder
              </Typography.Text>
              <Row>
                <Typography.Text className="text-[#2A2E36] text-[14px] leading-[22px] mr-1">
                  {getTime(currentEvent?.nag_time)}
                </Typography.Text>
                <Typography.Text className="text-[#006098] text-[14px] leading-[22px]">
                  {currentEvent?.summary}
                </Typography.Text>
              </Row>
              <Row className="justify-between items-center mt-[8px] mb-[20px]">
                <Row className="flex-col">{Timer}</Row>
                <Button
                  danger
                  onClick={handleClear}
                  loading={updateNagLoadingType === "clear"}
                >
                  Clear
                </Button>
              </Row>
              <Typography.Text className="text-[#2A2E36] text-[14px] leading-[22px] mb-[4px]">
                Extend Time
              </Typography.Text>
              <Row className="items-center">
                <Button
                  className="rounded-2xl mr-2"
                  onClick={() => handleExtendTime(2)}
                  loading={updateNagLoadingType === "extend_by_2"}
                >
                  +2 minutes
                </Button>
                <Button
                  className="rounded-2xl mr-2"
                  onClick={() => handleExtendTime(5)}
                  loading={updateNagLoadingType === "extend_by_5"}
                >
                  +5 minutes
                </Button>
              </Row>
              <Row className="mt-[10px] w-full">
                <Collapse
                  ghost
                  items={nagEvents}
                  expandIconPosition={"end"}
                  className="w-full"
                />
              </Row>
            </>
          )}
          {nagPastEvents?.length && (
            // <Row className="w-full">
            //   <Collapse
            //     ghost
            //     items={nagPastEvents}
            //     expandIconPosition={"end"}
            //     className="w-full"
            //   />
            // </Row>
            <></>
          )}
        </Row>
      </div>
    </div>
  );
};

export default NagModalBox;
