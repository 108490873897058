import apiClient from "@/services/apiClient/index.js";

export const urls = {
  getUserDetails: `/api/userinfo/`,
  getUserToken: (code) => `/google-login-callback/?code=${code}`,
  getUserCalendarDetails: (code) => `/connect-gcal-callback/?code=${code}`,
  getUserMailDetails: (code) => `/api/connect-gmail-callback/?code=${code}`,
  markUserOnboard: `/api/mark-user-onboarded/`,
  updateSettings: `/api/settings/`,
};

export default {
  getUserDetails: () => apiClient.get(urls.getUserDetails),
  getUserToken: (code) => apiClient.get(urls.getUserToken(code)),
  getUserCalendarDetails: (code) => apiClient.get(urls.getUserCalendarDetails(code)),
  getUserMailDetails: (code) => apiClient.get(urls.getUserMailDetails(code)),
  markUserOnboard: () => apiClient.post(urls.markUserOnboard),
  updateSettings: (payload) => apiClient.put(urls.updateSettings, payload),
};
