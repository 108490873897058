import { create } from "zustand";

const useChartPills = create((set) => ({
  type: "avg",
  week_type: "review",
  pills: {
    trackedHours: 0,
    trackedEntry: 0,
    trackedTimeBudget: 0,
  },
  setPills: (newPills) => {
    set((state) => ({
      pills: { ...state.pills, ...newPills },
    }));
  },
  setType: (newType) => {
    set(() => ({
      type: newType,
    }));
  },
  setWeekType: (newType) => {
    set(() => ({
      week_type: newType,
    }));
  },
}));

export default useChartPills;
