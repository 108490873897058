import prod from "@/environment/api/prod.js";
import staging from "@/environment/api/staging.js";

let environment = staging;
if (import.meta.env.VITE_ACTIVE_ENV === "STAGING") {
  environment = staging;
} else if (import.meta.env.VITE_ACTIVE_ENV === "PRODUCTION") {
  environment = prod;
}

export default environment;
