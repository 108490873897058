import { useNavigate, useRouteError } from "react-router-dom";
import { useEffect } from "react";
import { getAccessToken } from "@/utils/index.js";
import { message } from "antd";

const Error = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  const accessToken = getAccessToken();

  useEffect(() => {
    if (!accessToken) navigate("/login");
    if (accessToken) {
      message.error("Something went wrong. Please try again in some time.");
      navigate("/overview");
    }
    console.error(error.data);
  }, [accessToken, error, navigate]);

  return null;
};
export default Error;
