import { Avatar, Button, Empty, Menu, Row, Spin, Typography } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useMemo, useState } from "react";
import CategoryModal from "@/components/CategoryModal/index.jsx";
import useSubcategory from "@/store/useSubcategory.js";

const CategorySettings = () => {
  const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false);
  const [categoryItem, setCategoryItem] = useState({});
  const [parentCategory, setParentCategory] = useState([]);

  const [openKeys, setOpenKeys] = useState([]);

  const { data, isLoading } = useSubcategory();

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const handleAddSubcategory = (parent, categoryItem, isEditCategory = false) => {
    const parentArray = parent.split(",");
    if (isEditCategory) {
      setParentCategory(parentArray.slice(0, parentArray.length - 1));
      setCategoryItem({ ...categoryItem, isEditCategory: true });
    } else {
      setParentCategory(parentArray);
      setCategoryItem({ ...categoryItem });
    }
    setIsCategoryModalVisible(true);
  };
  const getCategoryItem = (item, parent) => {
    return {
      label: (
        <Row className="items-center justify-between">
          <div>
            <Avatar
              className="mr-2 w-[12px] h-[12px]"
              style={{
                background: item?.color,
              }}
            />
            {item?.name}
          </div>
          <div>
            <Button
              type="text"
              className="p-1 m-0 mr-1"
              onClick={(e) => {
                e.preventDefault();
                handleAddSubcategory(parent + item.name, item);
              }}
            >
              <PlusOutlined className="text-[10px]" />
            </Button>
            <Button
              type="text"
              className="p-1 m-0 mr-1"
              onClick={(e) => {
                e.preventDefault();
                handleAddSubcategory(parent + item.name, item, true);
              }}
            >
              <EditOutlined className="text-[10px]" />
            </Button>
          </div>
        </Row>
      ),
      key: item?.id,
      ...(item?.childs?.length > 0 && {
        children: item?.childs?.map((subItem) =>
          getCategoryItem(subItem, parent + item.name + ","),
        ),
      }),
    };
  };

  const menuItems = useMemo(() => {
    return (
      data?.data?.categories
        ?.filter((category) => category.name !== "unassigned")
        ?.map((subItem) => getCategoryItem(subItem, "")) || []
    );
    // eslint-disable-next-line
  }, [data]);

  return (
    <Row className="flex flex-col mb-[40px]">
      <Button
        icon={<PlusOutlined />}
        type="primary"
        className="bg-primaryDark w-fit mb-[12px]"
        onClick={() => setIsCategoryModalVisible(true)}
      >
        Add category
      </Button>
      <Typography.Text className="text-[14px] leading-[22px] font-semibold my-[10px]">
        My Categories
      </Typography.Text>
      {isLoading && (
        <Row className="items-center justify-between">
          <Spin className="w-[50%]" />
        </Row>
      )}
      {menuItems?.length === 0 && !isLoading && <Empty />}
      <Menu
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        className="category-menu"
        items={menuItems}
        selectable={false}
        style={{
          borderRight: "unset",
          width: 450,
          padding: 0,
        }}
      />
      <CategoryModal
        isModalVisible={isCategoryModalVisible}
        onClose={() => {
          setIsCategoryModalVisible(false);
          setParentCategory([]);
          setCategoryItem({});
        }}
        defaultCategory={categoryItem}
        defaultParentCategory={parentCategory}
      />
    </Row>
  );
};

export default CategorySettings;
