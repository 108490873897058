import { Avatar, ColorPicker, Dropdown, Input, Row } from "antd";
import { colorItems } from "@/mocks/index.js";
import MultiColor from "@/assets/multi_colors.svg";
import { useState } from "react";

const ColorDropDown = ({ defaultColor, onChange, width = 444 }) => {
  const [selectedColor, setSelectedColor] = useState(defaultColor || "");
  const [isColorDropDown, setIsColorDropDown] = useState(false);

  return (
    <Dropdown
      overlay={
        <Row
          className={`p-[10px] flex-wrap w-[${width}px]`}
          style={{
            borderRadius: "2px",
            background: "#FFF",
            boxShadow:
              "0px 9px 28px 0px rgba(0, 0, 0, 0.08), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          {colorItems.map((value, index) => (
            <Avatar
              key={index}
              className="m-[10px] h-[40px] w-[40px] cursor-pointer"
              style={{ background: value }}
              onClick={() => {
                setSelectedColor(value);
                onChange(value);
                setIsColorDropDown(false);
              }}
            ></Avatar>
          ))}
          <ColorPicker
            className="z-100"
            format="hex"
            onChange={(value) => {
              let str = value.toHex();
              str = str.toUpperCase();
              setSelectedColor(`#${str}`);
              onChange(`#${str}`);
            }}
          >
            <MultiColor
              className="m-[10px] h-[40px] w-[40px] cursor-pointer"
              onClick={() => setIsColorDropDown(false)}
            />
          </ColorPicker>
        </Row>
      }
      visible={isColorDropDown}
      onVisibleChange={() => setIsColorDropDown(!isColorDropDown)}
      trigger={["click"]}
      placement="bottomRight"
    >
      <Input
        className={`items-center w-[${width}px]`}
        value={selectedColor}
        prefix={
          <Avatar
            className="mr-2 w-[12px] h-[12px]"
            style={{ background: selectedColor }}
          />
        }
      />
    </Dropdown>
  );
};

export default ColorDropDown;
