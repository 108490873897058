import useSWR from "swr";
import userService from "@/services/apiServices/userService/index.js";
import { getAccessToken, setAccessToken } from "@/utils/index.js";
import { googleLogout } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";

const useUser = () => {
  const accessToken = getAccessToken();
  const navigate = useNavigate();

  const data = useSWR(
    accessToken || accessToken?.length > 0 ? `fetchUserData` : null,
    userService.getUserDetails,
  );

  if (accessToken && data?.error && Object.keys(data?.error)?.length > 0) {
    googleLogout();
    navigate("/login");
    setAccessToken("");
    window.location.href = "/";
    localStorage.clear();
  }

  return data;
};

export default useUser;
