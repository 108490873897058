import { Button, message, Row, Typography } from "antd";
import useLocalStorage from "@/hooks/useLocalStorage.js";
import { mutate } from "swr";
import useUser from "@/store/useUser.js";
import { useMemo } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import useLoading from "@/hooks/useLoading.js";
import userService from "@/services/apiServices/userService/index.js";

const ReminderCategory = () => {
  const { data: userData } = useUser();
  const user = userData?.data;
  const [isVisible, setIsVisible] = useLocalStorage("reminder_revoked_calendar", true);

  const { executeAction: fetchCalendarData, loading: isCalendarLoading } = useLoading(
    async (authResponse) => {
      try {
        const currentCalendarData = await userService.getUserCalendarDetails(
          authResponse?.code,
        );
        if (currentCalendarData?.data) {
          await mutate((key) => key, undefined, { revalidate: true });
          message.success("Calendar Connected Successfully.");
        } else if (currentCalendarData?.data?.error) {
          message.error("Something went wrong. Please try again in some time.");
        }
      } catch {
        message.error("Something went wrong. Please try again in some time.");
      }
    },
  );

  const revokedCalendar = useMemo(() => {
    return user?.calendars
      ?.filter?.((calendar) => calendar.revoked)
      ?.map((calendar) => calendar.title);
  }, [user]);

  const handleCalendarConnect = useGoogleLogin({
    onSuccess: (codeResponse) => fetchCalendarData(codeResponse),
    onError: (error) => {
      if (typeof error?.error === "string") message.error("calendar" + " " + error.error);
      else console.error(error);
    },
    onNonOAuthError: (error) => console.error(error),
    scope: "https://www.googleapis.com/auth/calendar",
    flow: "auth-code",
  });

  if (!isVisible || revokedCalendar?.length === 0) return null;

  return (
    <Row
      className="justify-between items-center mt-[20px] px-[30px] py-[6px] bg-white w-full"
      style={{
        borderRadius: "10px",
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
      }}
    >
      <Row className="items-center text-[14px] leading-[22px]">
        <Row className="items-center">
          <Typography.Text className="text-[red]">
            {revokedCalendar?.join(", ") + " "}
            access has been revoked please provide the access by connecting your calendar
          </Typography.Text>
        </Row>
      </Row>
      <Row>
        {revokedCalendar?.length > 0 && (
          <Button
            type="default"
            className="mr-4"
            onClick={handleCalendarConnect}
            loading={isCalendarLoading}
          >
            Connect calendar
          </Button>
        )}
        <Button onClick={() => setIsVisible(false)} type="primary" className="bg-primary">
          Hide
        </Button>
      </Row>
    </Row>
  );
};

export default ReminderCategory;
