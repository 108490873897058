import { message, Row, Select, TreeSelect, Typography } from "antd";
import useUser from "@/store/useUser.js";
import { useMemo } from "react";
import insightServices from "@/services/apiServices/insightServices/index.js";

const CalendarSettings = () => {
  const { data: userData, mutate: mutateUserData } = useUser();

  const defaultSelectedAddCalendarValue = useMemo(() => {
    let defaultValue = "";
    userData?.data?.subcalendars_with_write_access?.forEach((calendar) => {
      if (calendar?.primary_scheduling === true) {
        defaultValue = calendar?.id;
      }
    });
    return defaultValue;
  }, [userData]);

  const defaultSelectedConflictCalendarValues = useMemo(() => {
    const defaultValues = [];
    userData?.data?.calendars?.forEach((calendar) => {
      calendar?.subcalendars?.forEach((subcalendar) => {
        if (subcalendar?.used_for_conflicts === true) {
          defaultValues.push(subcalendar?.id);
        }
      });
    });
    return defaultValues;
  }, [userData]);

  const calendarTreeData = useMemo(() => {
    return (
      userData?.data?.calendars?.map((calendar) => {
        return {
          title: calendar?.title,
          key: `${calendar?.title} ${calendar?.id}`,
          value: `${calendar?.title} ${calendar?.id}`,
          children: calendar?.subcalendars?.map((subcalendar) => {
            return {
              title: subcalendar?.title,
              key: subcalendar?.id,
              value: subcalendar?.id,
            };
          }),
        };
      }) || []
    );
  }, [userData]);

  async function markAddCalendarId(value) {
    try {
      const payload = {
        subcalendar_id: value,
      };
      await insightServices.markAddCalendarId(payload);
      await mutateUserData();
      message.success("Calendar updated successfully.");
    } catch (e) {
      message.error(
        typeof e?.response?.data?.msg === typeof String()
          ? e.response.data.msg
          : "Something went wrong. Please try again in some time.",
      );
    }
  }

  async function markConflictCalendarIds(values) {
    try {
      const payload = {
        subcalendar_ids: values,
      };
      await insightServices.markConflictCalendarIds(payload);
      await mutateUserData();
      message.success("Calendar updated successfully.");
    } catch (e) {
      message.error(
        typeof e?.response?.data?.msg === typeof String()
          ? e.response.data.msg
          : "Something went wrong. Please try again in some time.",
      );
    }
  }

  return (
    <Row className="flex flex-col mb-[40px]">
      <Row className="flex flex-col mb-[20px]">
        <Typography.Text className="text-[14px] leading-[22px] font-semibold mb-[4px]">
          Check for conflicts
        </Typography.Text>
        <Typography.Text className="text-[12px] leading-[22px]  mb-[4px] w-full">
          Set the calendar(s) to check for conflicts to prevent double bookings.
        </Typography.Text>
        <Typography.Text className="text-[12px] leading-[22px]  mb-[10px] w-full">
          If no calendar is selected, all connected calendars will be used to check for
          conflicts.
        </Typography.Text>
        <TreeSelect
          defaultValue={defaultSelectedConflictCalendarValues}
          onChange={markConflictCalendarIds}
          treeData={calendarTreeData}
          placeholder="Select Calendar"
          treeCheckable={true}
          className="w-[440px]"
        />
      </Row>
      <Row>
        <Typography.Text className="text-[14px] leading-[22px] font-semibold mb-[4px]">
          Add to Calendar
        </Typography.Text>
        <Typography.Text className="text-[12px] leading-[22px]  mb-[10px] w-full">
          Set the calendar you would like to add new events to as they are scheduled.
        </Typography.Text>
        <Select
          showSearch
          clearIcon
          className="w-[428px]"
          placeholder="Select Calendar"
          defaultValue={defaultSelectedAddCalendarValue}
          onSelect={markAddCalendarId}
        >
          {userData?.data?.subcalendars_with_write_access?.map((calendar) => (
            <Select.Option value={calendar.id} key={calendar.id}>
              {calendar?.calendar_title + " / " + calendar?.subcalendar_title}
            </Select.Option>
          ))}
        </Select>
      </Row>
    </Row>
  );
};

export default CalendarSettings;
