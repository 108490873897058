import { Button, message, Row, Typography } from "antd";
import LoginBackground from "@/assets/login_background.svg";
import { useGoogleLogin } from "@react-oauth/google";
import Google from "@/assets/google.svg";

const SignUp = ({ onClick }) => {
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => onClick(codeResponse),
    onError: (error) => {
      if (typeof error?.error === "string") message.error(error?.error);
      else console.error(error);
    },
    onNonOAuthError: (error) => console.error(error?.error),
    flow: "auth-code",
  });

  return (
    <Row className="items-center justify-center text-center min-h-[100vh]">
      <div className="flex flex-col justify-center items-center">
        <Row className="mb-[20px]">
          <LoginBackground />
        </Row>
        <Row className="mb-[60px]">
          <Typography.Text>Sign in with Google to login or register</Typography.Text>
        </Row>
        <Row>
          <Button
            icon={<Google className="mr-2 hight-[32px] width-[32px]" />}
            className="flex justify-center items-center p-6"
            onClick={login}
          >
            Sign in with Google
          </Button>
        </Row>
      </div>
    </Row>
  );
};

export default SignUp;
