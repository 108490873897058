import { clearInterval, setInterval } from "worker-timers";
import { create } from "zustand";
import dayjs from "dayjs";
let id;

const useReminderTimer = create((set) => ({
  time: dayjs().set("hour", 0).set("minute", 0).set("second", 0),
  isRunning: false,
  isPlaying: false,
  events: [],
  pastEvents: [],
  currentEvent: {},
  start: (time) => {
    if (id) {
      clearInterval(id);
      id = undefined;
    }
    set(() => ({
      time: dayjs(time)
        .set("hour", dayjs(time).get("hours") - dayjs().get("hours"))
        .set("minute", dayjs(time).get("minutes") - dayjs().get("minutes"))
        .set("second", dayjs(time).get("seconds") - dayjs().get("seconds")),
      isRunning: true,
    }));
    if (!id) {
      id = setInterval(() => {
        set((state) => ({
          time:
            state.time.get("second") === 0 &&
            state.time.get("minute") === 0 &&
            state.time.get("hour") === 0
              ? state.time
              : dayjs(time)
                  .set("hour", dayjs(time).get("hours") - dayjs().get("hours"))
                  .set("minute", dayjs(time).get("minutes") - dayjs().get("minutes"))
                  .set("second", dayjs(time).get("seconds") - dayjs().get("seconds")),
        }));
      }, 1000);
    }
  },
  setEvents: (newEvents) => {
    set({
      events: [...newEvents],
    });
  },
  setPastEvents: (newEvents) => {
    set({
      pastEvents: [...newEvents],
    });
  },
  setCurrentEvent: (newEvent) => {
    set({
      currentEvent: { ...newEvent },
    });
  },
  setIsPlaying: (newIsPlaying) => {
    set({
      isPlaying: newIsPlaying,
    });
  },
}));

export default useReminderTimer;
