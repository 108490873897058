import { useMemo } from "react";
import ConnectTimingApp from "@/components/ConnectTimingApp/index.jsx";
import ConnectAppInbox from "@/components/ConnectAppInbox/index.jsx";
import ConnectJiraApp from "@/components/ConnectJiraApp/index.jsx";

const ConnectedApps = () => {
  const apps = useMemo(
    () => [
      {
        key: "timing",
        component: <ConnectTimingApp />,
      },
      {
        key: "active-inbox",
        component: <ConnectAppInbox />,
      },
      {
        key: "connect-jira",
        component: <ConnectJiraApp />,
      },
    ],
    [],
  );

  return (
    <div>
      {apps.map((app) => (
        <div key={app.key} className="mb-[20px]">
          {app.component}
        </div>
      ))}
    </div>
  );
};

export default ConnectedApps;
