import { Button, Row, Typography } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import useLocalStorage from "@/hooks/useLocalStorage.js";
import useSWR from "swr";
import insightServices from "@/services/apiServices/insightServices/index.js";
import dayjs from "dayjs";
import useCalendar from "@/store/useCalendar.js";

const ReminderCategory = () => {
  const [isVisible, setIsVisible] = useLocalStorage("reminder", true);
  const calendarIds = useCalendar((store) => store.calendarIds);
  const subCalendarIds = useCalendar((store) => store.subCalendarIds);
  const { data } = useSWR(`fetchTaggedEventsData/${calendarIds}/${subCalendarIds}`, () =>
    insightServices.getTaggedEvents(
      {
        startDate: dayjs().day(0).format("YYYY-MM-DD"),
        endDate: dayjs().day(6).format("YYYY-MM-DD"),
      },
      { calendarIds, subCalendarIds },
    ),
  );
  if (!isVisible || data?.data?.total - data?.data?.tagged === 0 || !data) return null;
  return (
    <Row
      className="justify-between items-center px-[30px] py-[6px] bg-white w-full"
      style={{
        borderRadius: "10px",
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
      }}
    >
      <Row className="items-center text-[14px] leading-[22px]">
        <InfoCircleFilled className="mr-2 text-primary" />
        <Typography.Text>
          You have {data?.data ? data?.data?.total - data?.data?.tagged : 0} uncategorised
          entries.
        </Typography.Text>
      </Row>
      <Button onClick={() => setIsVisible(false)} type="primary" className="bg-primary">
        Hide
      </Button>
    </Row>
  );
};

export default ReminderCategory;
