import axios from "axios";
import { getHeaders } from "@/services/apiClient/apiUtil.js";
import environment from "@/environment/index.js";

export function setAxiosInstanceHeaders(instance) {
  const headers = getHeaders();
  Object.keys(headers).forEach((headerKey) => {
    instance.defaults.headers.common[headerKey] = headers[headerKey];
  });
}

const { BASE_URL } = environment;

const apiClient = axios.create({
  baseURL: BASE_URL,
});

setAxiosInstanceHeaders(apiClient);

export default apiClient;
