import { Suspense, useEffect, useState } from "react";
import {
  ClockCircleOutlined,
  DashboardOutlined,
  FieldTimeOutlined,
  FileDoneOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Row, Spin } from "antd";
import { Link, Outlet, useLocation } from "react-router-dom";
import Header from "@/components/Header/index.jsx";
import FiniteTimeFavicon from "@/assets/new_finitetime_logo.svg";
import FiniteTimeLogo from "@/assets/new_finitetime_logo_box.svg";
import "./Home.css";
import Timer from "@/components/Timer/index.jsx";
import TodoButton from "@/components/TodoButton/index.jsx";

const { Content, Sider } = Layout;

function getItem(label, key, icon) {
  return {
    key,
    icon,
    label,
  };
}

const sidebarItems = [
  getItem("Timeline", "/timeline", <FieldTimeOutlined />),
  getItem("Dashboard", "/overview", <DashboardOutlined />),
  getItem("To-do", "/todo", <FileDoneOutlined />),
  getItem("Scheduling links", "/schedule-link", <LinkOutlined />),
  getItem("Time budget", "/time-budget", <ClockCircleOutlined />),
];

const Home = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  const [pathname, setPathname] = useState(location?.pathname);

  useEffect(() => {
    setPathname(location?.pathname);
  }, [location?.pathname]);

  return (
    <Layout style={{ minHeight: "100vh" }} key={pathname}>
      <Sider
        collapsible
        theme="light"
        collapsed={collapsed}
        onCollapse={() => {
          setCollapsed(!collapsed);
        }}
        width="280"
        style={{
          overflow: "auto",
          height: "95vh",
          position: "fixed",
          left: 0,
        }}
      >
        <div className="mt-[28px] mb-[20px]">
          {collapsed ? (
            <div className="whitespace-normal">
              <FiniteTimeFavicon className="w-[36%] mx-[20px]" />
            </div>
          ) : (
            <FiniteTimeLogo className="w-[70%] h-[50%]" />
          )}
        </div>
        <Menu
          defaultSelectedKeys={[`${pathname}`]}
          mode="inline"
          className="side-panel-menu"
        >
          {!collapsed && (
            <div className="flex-col">
              <TodoButton />
              <Menu.Divider style={{ margin: "40px 0" }} />
            </div>
          )}
          {sidebarItems.map((panel) => (
            <Menu.Item key={panel.key} icon={<div>{panel.icon}</div>}>
              <Link to={panel.key} className="mb-1 font-semibold pr-3">
                {panel.label}
              </Link>
            </Menu.Item>
          ))}
          {!collapsed && (
            <>
              <Menu.Divider style={{ margin: 0, marginTop: "40px" }} />
              <Timer />
            </>
          )}
        </Menu>
      </Sider>
      <Layout>
        <Content
          width="100%"
          style={{
            overflow: "auto",
            marginLeft: !collapsed ? "308px" : "108px",
            marginRight: "2%",
            transition: "all 0.1s ease-in-out",
            position: "relative",
            marginBottom: "24px",
          }}
        >
          <div className="w-full flex flex-col">
            <Header />
            <Suspense
              fallback={
                <Row className="h-screen justify-center items-center w-full">
                  <Spin />
                </Row>
              }
            >
              <Outlet />
            </Suspense>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Home;
