// import { getCookieId, getLoginMethod, isMobile, getAccessToken} from "@/utils";

import { getAccessToken } from "@/utils/index.js";

export const getHeaders = () => ({
  ...(getAccessToken() && { Authorization: `Token ${getAccessToken() || ""}` || "" }),
  "X-User-Timezone": Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
  // "NBAI-PAGE-URL": (window && window.location.href) || "",
  // "NBAI-REFERRER-URL": window.sessionStorage.getItem("referrerUrl") || "",
  // "NBAI-USER-AGENT": window.navigator.userAgent,
  // "NBAI-DEVICE": isMobile(),
  // "NBAI-COOKIE-ID": getCookieId(),
  // "NBAI-LOGIN-METHOD": getLoginMethod() || "",
  // "NBAI-ACCESS-TOKEN": getAccessToken(),
  // "X-NBAI-CALLER": "nbai-service",
  // "x-NBAI-mus-caller": "nbaiservice",
});
