import useReminderTimer from "@/store/useReminderTimer.js";
import useSWR from "swr";
import insightServices from "@/services/apiServices/insightServices/index.js";
import { useEffect, useMemo } from "react";
import dayjs from "dayjs";
import { clearInterval, setInterval } from "worker-timers";
import eventBus from "@/utils/eventBus.js";
import { EVENT_BUS_EVENTS } from "@/constants/index.js";
import NagToast from "@/components/NagToast/index.jsx";
import NagModalBox from "@/components/NagModalBox/index.jsx";
import AlertClip from "@/assets/alert_clip.mp3";

const sortEventsByTime = (a, b) =>
  new Date(a?.nag_time)?.getTime() < new Date(b?.nag_time)?.getTime() ? -1 : 1;

var song = new Audio(AlertClip);
var playPromise = undefined;
song = new Audio(AlertClip);
song.controls = false;
song.muted = true;
song.loop = true;
song.autoplay = true;

const NagModalContainer = ({ isModal, isVisible, setIsVisible }) => {
  const time = useReminderTimer((store) => store.time);
  const start = useReminderTimer((store) => store.start);
  const isRunning = useReminderTimer((store) => store.isRunning);
  const events = useReminderTimer((store) => store.events);
  const setEvents = useReminderTimer((store) => store.setEvents);
  const pastEvents = useReminderTimer((store) => store.pastEvents);
  const setPastEvents = useReminderTimer((store) => store.setPastEvents);
  const currentEvent = useReminderTimer((store) => store.currentEvent);
  const setCurrentEvent = useReminderTimer((store) => store.setCurrentEvent);
  const isPlaying = useReminderTimer((store) => store.isPlaying);
  const setIsPlaying = useReminderTimer((store) => store.setIsPlaying);

  const { data: eventReminderData, mutate: mutateEventReminderData } = useSWR(
    "fetchEventRemindersData",
    insightServices.getEventReminders,
  );

  const isTimerAboutToPlay = useMemo(() => {
    return (
      isRunning &&
      currentEvent?.nag_time &&
      time.get("second") < 60 &&
      time.get("minute") === 0 &&
      time.get("hour") === 0
    );
  }, [isRunning, currentEvent, time]);

  useEffect(() => {
    if (isTimerAboutToPlay && !isVisible) setIsVisible(true);
    // eslint-disable-next-line
  }, [isTimerAboutToPlay, isVisible]);

  useEffect(() => {
    let nagEvents = eventReminderData?.data?.nags || [];

    // sort every event by time
    nagEvents?.sort(sortEventsByTime);

    // upcoming and past events
    let upcomingEvents = nagEvents?.filter(
      (event) => event?.nag_status === "upcoming" || event?.nag_status === "alarm",
    );
    let pastEvents = nagEvents?.filter((event) => event?.nag_status === "ack");

    // current running timer event
    const currentRunningTimerEvent = upcomingEvents?.filter(
      (event) => new Date().getTime() <= new Date(event?.nag_time).getTime(),
    )?.[0];
    upcomingEvents = upcomingEvents?.filter(
      (event) => currentRunningTimerEvent?.id !== event.id,
    );

    setEvents(upcomingEvents);
    setCurrentEvent(currentRunningTimerEvent);
    setPastEvents(pastEvents);

    // start timer for currentAlarm event
    start?.(dayjs(currentRunningTimerEvent?.nag_time));
    // eslint-disable-next-line
  }, [eventReminderData]);

  useEffect(() => {
    const autoUpdateId = setInterval(() => {
      if (!isTimerAboutToPlay && isVisible && !isModal) mutateEventReminderData();
      if (!isTimerAboutToPlay && !isVisible && isModal) mutateEventReminderData();
    }, 10000);
    return () => clearInterval(autoUpdateId);
    // eslint-disable-next-line
  }, [isTimerAboutToPlay, isVisible]);

  useEffect(() => {
    const cb = async () => {
      await mutateEventReminderData();
    };
    eventBus.on(EVENT_BUS_EVENTS.REVALIDATE_EVENT_REMINDERS, cb);
    return () => eventBus.off(EVENT_BUS_EVENTS.REVALIDATE_EVENT_REMINDERS, cb);
    /* eslint-disable-next-line */
  }, []);

  function playAudio() {
    song.muted = false;
    playPromise = song.play();
    setIsPlaying(true);
  }

  function stopAudio() {
    if (playPromise !== undefined) {
      playPromise.then(() => {
        song.pause();
        playPromise = undefined;
      });
    }
    setIsPlaying(false);
  }

  if (!isModal)
    return (
      <NagToast
        timer={{
          isVisible,
          setIsVisible,
          time,
          isRunning,
          events,
          currentEvent,
          pastEvents,
          isPlaying,
          setIsPlaying,
          playAudio,
          stopAudio,
        }}
        mutateEventReminderData={mutateEventReminderData}
      />
    );
  return (
    <NagModalBox
      timer={{
        isVisible,
        setIsVisible,
        time,
        isRunning,
        events,
        currentEvent,
        pastEvents,
        isPlaying,
        setIsPlaying,
        playAudio,
        stopAudio,
      }}
      mutateEventReminderData={mutateEventReminderData}
    />
  );
};

export default NagModalContainer;
