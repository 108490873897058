import {
  Button,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  message,
  Row,
  Space,
  Spin,
  theme,
  TimePicker,
} from "antd";
import { CopyOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { cloneElement, useEffect, useState } from "react";
import dayjs from "dayjs";
import useSWR from "swr";
import insightServices from "@/services/apiServices/insightServices/index.js";
import useLoading from "@/hooks/useLoading.js";

const format = "HH:mm";
const { useToken } = theme;

const getDefaultPreferences = (workPrefData) => {
  return workPrefData?.map((newPreference) => {
    return {
      ...newPreference,
      disabled: newPreference.slots.length === 0,
    };
  });
};
const WeekPreferenceModal = () => {
  const {
    data: workPrefData,
    isLoading,
    mutate,
  } = useSWR("fetchWeekPrefData", insightServices.getWeekPref);

  const [preferences, setPreferences] = useState([]);
  const [cloneDays, setCloneDays] = useState([]);
  const [cloneDayIndex, setCloneDayIndex] = useState(-1);
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const { token } = useToken();

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: "none",
  };

  useEffect(() => {
    setPreferences(getDefaultPreferences(workPrefData?.data?.work_prefs) || []);
  }, [workPrefData]);

  const handleAddPreference = (preference) => {
    const filteredPreferences = preferences.map((item) => {
      if (preference.day === item.day) {
        if (
          item?.slots?.[item?.slots?.length - 1] &&
          Object.values(item?.slots?.[item?.slots?.length - 1])?.every(
            (item) => item !== "",
          )
        ) {
          item.slots.push({
            start_time: "",
            end_time: "",
          });
        }
      }
      return item;
    });
    setPreferences(filteredPreferences);
    handleSavePreferences(filteredPreferences);
  };
  const handleDeletePreference = (newPreference, newSlot) => {
    const filteredPreferences = preferences.map((preference) => {
      if (newPreference.day === preference.day) {
        if (preference.slots.length === 1) {
          preference.slots = [{ start_time: "", end_time: "" }];
          preference.disabled = !preference.disabled;
          return preference;
        } else
          preference.slots = preference.slots.filter(
            (currentSlot) =>
              currentSlot.start_time !== newSlot.start_time &&
              currentSlot.end_time !== newSlot.end_time,
          );
      }
      return preference;
    });
    setPreferences(filteredPreferences);
    handleSavePreferences(filteredPreferences);
  };

  const handleTimeSelect = (timeValue, selectedSlot, selectedPreference) => {
    const filteredPreferences = preferences.map((preference) => {
      if (selectedPreference.day === preference.day) {
        if (preference.slots.length === 0) {
          if (timeValue?.[0] && timeValue?.[1])
            preference.slots.push({
              start_time: dayjs(timeValue[0]).format(format),
              end_time: dayjs(timeValue[1]).format(format),
            });
          else
            preference.slots.push({
              start_time: "",
              end_time: "",
            });
        } else {
          if (timeValue?.[0] && timeValue?.[1])
            preference.slots[selectedSlot] = {
              start_time: dayjs(timeValue[0]).format(format),
              end_time: dayjs(timeValue[1]).format(format),
            };
          else {
            preference.slots[selectedSlot] = {
              start_time: "",
              end_time: "",
            };
          }
        }
      }
      return preference;
    });
    setPreferences(filteredPreferences);
    handleSavePreferences(filteredPreferences);
  };

  const handleSelectPreference = (newPreference) => {
    const filteredPreferences = preferences.map((preference) => {
      if (newPreference.day === preference.day) {
        preference.disabled = !preference.disabled;
      }
      return preference;
    });
    setPreferences(filteredPreferences);
    handleSavePreferences(filteredPreferences);
  };

  const { executeAction: handleSavePreferences } = useLoading(async (preferences) => {
    try {
      const empty = (element) =>
        element === "" || element?.start_time === "" || element?.end_time === "";
      const payload = preferences.map((preference) => {
        const newPreference = { ...preference };
        if (newPreference.disabled) newPreference.slots = [];
        else if (!newPreference.disabled && newPreference.slots.length === 0)
          newPreference.slots = [{ start_time: "", end_time: "" }];
        delete newPreference.disabled;
        return newPreference;
      });
      let error = "";
      payload.forEach((preference) => {
        if (preference.slots.some(empty)) {
          error = "Please fill the details of selected preference";
          return;
        }
      });
      if (error) {
        // message.warning(error);
        return;
      }
      await insightServices.saveWorkPreferences({
        work_prefs: payload,
      });
      await mutate();
      message.success("Preference Saved Successfully.");
    } catch (e) {
      message.error(
        typeof e?.response?.data?.err === typeof String()
          ? e.response.data.err
          : "Something went wrong. Please try again in some time.",
      );
    }
  });

  const handleDaySelect = (day) => {
    if (!cloneDays.includes(day)) setCloneDays([...cloneDays, day]);
    else setCloneDays(cloneDays.filter((currentDay) => currentDay !== day));
  };

  const dayItems = days.map((day) => {
    return {
      key: day,
      label: (
        <div onClick={() => handleDaySelect(day)}>
          <Checkbox checked={cloneDays.includes(day)} className="mr-2" /> {day}
        </div>
      ),
    };
  });

  const resetDays = () => {
    setCloneDays([]);
    setCloneDayIndex(-1);
  };

  const handleCloneDays = (currentPreference) => {
    const selectedDays = [...cloneDays];

    const filteredPreferences = preferences.map((preference) => {
      if (selectedDays.includes(preference.day)) {
        return {
          ...currentPreference,
          day: preference.day,
        };
      }
      return preference;
    });
    handleSavePreferences(filteredPreferences);
    setPreferences(filteredPreferences);
    resetDays();
  };

  return (
    <div className="w-full">
      <div className="min-h-[516px] min-w-[520px]">
        {isLoading ? (
          <Row className="items-center justify-center h-[516px] w-[520px]">
            <Spin />
          </Row>
        ) : (
          <div>
            {preferences?.map((preference, index) => (
              <div key={index}>
                <Row className="w-full pb-[10px] flex-nowrap">
                  <Col className={"w-[90px] mt-1"}>
                    <Checkbox
                      className="mr-[8px]"
                      onClick={() => handleSelectPreference(preference)}
                      checked={!preference.disabled}
                    />
                    <span className="text-[14px] mr-4">{preference.day}</span>
                  </Col>
                  <Col className="mr-[42px]">
                    {preference.slots.length ? (
                      preference.slots.map((slot, index) => (
                        <Row
                          key={`${slot?.start_time} ${slot?.end_time} ${index}`}
                          className="mb-2 items-center"
                        >
                          {slot?.start_time?.length !== 0 &&
                          slot?.end_time?.length !== 0 ? (
                            <TimePicker.RangePicker
                              format={format}
                              className="mr-2 w-[320px]"
                              defaultValue={[
                                dayjs(slot.start_time, format),
                                dayjs(slot.end_time, format),
                              ]}
                              onChange={(value) =>
                                handleTimeSelect(value, index, preference)
                              }
                              disabled={preference.disabled}
                            />
                          ) : (
                            <TimePicker.RangePicker
                              format={format}
                              className="mr-2 w-[320px]"
                              onChange={(value) =>
                                handleTimeSelect(value, index, preference)
                              }
                              disabled={preference.disabled}
                            />
                          )}
                          <Button
                            type="text"
                            icon={<DeleteOutlined />}
                            onClick={() => handleDeletePreference(preference, slot)}
                            disabled={preference.disabled}
                          />
                        </Row>
                      ))
                    ) : (
                      <Row className="mb-2 items-center">
                        <TimePicker.RangePicker
                          format={format}
                          className="mr-2 w-[320px]"
                          disabled={preference.disabled}
                          onChange={(value) => handleTimeSelect(value, index, preference)}
                        />
                        <Button
                          type="text"
                          icon={<DeleteOutlined />}
                          onClick={() => handleDeletePreference(preference)}
                          disabled={preference.disabled}
                        />
                      </Row>
                    )}
                  </Col>
                  <Col>
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={() => handleAddPreference(preference)}
                      disabled={preference.disabled}
                    />
                  </Col>
                  <Col key={index}>
                    <Dropdown
                      menu={{
                        items: dayItems,
                      }}
                      open={index === cloneDayIndex}
                      onOpenChange={() => resetDays()}
                      trigger={["click"]}
                      placement="bottomRight"
                      disabled={preference.disabled}
                      className="m-0 p-0"
                      dropdownRender={(menu) => (
                        <div style={contentStyle}>
                          {cloneElement(menu, { style: menuStyle })}
                          <Divider style={{ margin: 0 }} />
                          <Space style={{ padding: 8 }}>
                            <Button
                              type="primary"
                              className="bg-primary"
                              onClick={() => handleCloneDays(preference)}
                            >
                              Clone Day
                            </Button>
                          </Space>
                        </div>
                      )}
                    >
                      <Button
                        type="text"
                        icon={<CopyOutlined />}
                        onClick={() => setCloneDayIndex(index)}
                        disabled={preference.disabled}
                      />
                    </Dropdown>
                  </Col>
                </Row>
                <div className="pb-[20px]" />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default WeekPreferenceModal;
