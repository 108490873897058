// https://www.pluralsight.com/guides/how-to-communicate-between-independent-components-in-reactjs

class EventBus {
  constructor() {
    this.listeners = new Map();
  }

  on(event, callback) {
    const currentCbs = this.listeners.get(event);
    this.listeners.set(event, [...(currentCbs || []), callback]);
  }

  emit(event, data) {
    const cbs = this.listeners.get(event);
    if (cbs) {
      cbs.forEach((cb) => cb(data));
    }
  }

  off(event, callback) {
    const cbs = this.listeners.get(event);
    if (!cbs) return;
    this.listeners.set(
      event,
      cbs.filter((currentCallback) => currentCallback !== callback),
    );
  }
}

const eventBus = new EventBus();

export default eventBus;
