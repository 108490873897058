import { Button, Col, message, Row, Select, Spin, Tooltip, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { EditFilled, PlusCircleOutlined } from "@ant-design/icons";
import { defaultPriorities } from "@/mocks/index.js";
import useSWR from "swr";
import insightServices from "@/services/apiServices/insightServices/index.js";
import CustomTable from "@/components/CustomTable/index.jsx";
import { getAccessToken, getCategoryIdMap, getCategoryName } from "@/utils/index.js";
import { iconMap } from "@/pages/Overview/OverviewEventTable.jsx";
import useLoading from "@/hooks/useLoading.js";
import userService from "@/services/apiServices/userService/index.js";
import { useNavigate } from "react-router-dom";
import { PRIORITY_PAIR } from "@/constants/index.js";
import useUser from "@/store/useUser.js";
import CategoryDropdown from "@/components/CategoryDropdown/index.jsx";
import useSubcategory from "@/store/useSubcategory.js";

const LoginCategories = () => {
  const { isValidating: isUserDataValidating, mutate: mutateUserData } = useUser();
  const { data: subcategoryData } = useSubcategory();
  const navigate = useNavigate();
  const accessToken = getAccessToken();
  const [selectedField, setSelectedField] = useState({
    rowData: {},
    colName: "",
    isHover: false,
  });

  const {
    data,
    isLoading,
    mutate: mutateRecEventData,
  } = useSWR(accessToken ? "fetchRecEventsData" : null, insightServices.getRecEvents);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    setEvents(data?.data?.events || []);
  }, [data]);

  const { executeAction: saveOption, loading: isOptionSaveLoading } = useLoading(
    async (payload) => {
      try {
        await insightServices.tagEvents(payload);
        message.success("Events Updated Successfully.");
      } catch (e) {
        message.error(
          typeof e?.response?.data?.err === typeof String()
            ? e.response.data.err
            : "Something went wrong. Please try again in some time.",
        );
      } finally {
        await mutateRecEventData();
        setSelectedField({
          rowData: {},
          colName: "",
          isHover: false,
        });
      }
    },
  );

  const { executeAction: handleOnboard, loading: isOnboarding } = useLoading(async () => {
    try {
      await userService.markUserOnboard();
      message.success("Save Successfully.");
      await mutateUserData();
      navigate("/overview");
    } catch (e) {
      message.error(
        typeof e?.response?.data?.err === typeof String()
          ? e.response.data.err
          : "Something went wrong. Please try again in some time.",
      );
    } finally {
      setSelectedField({
        rowData: {},
        colName: "",
        isHover: false,
      });
    }
  });

  const onFinish = async (values) => {
    const payload = [
      {
        id: values.id,
        category_id: values?.category_id,
        priority: values?.priority,
      },
    ];
    const newEvents = events.map((event) => {
      if (event.id === values.id) {
        return {
          ...event,
          category_id: values?.category_id || event?.category_id,
          priority: values?.priority || event?.priority,
        };
      }
      return event;
    });
    setEvents(newEvents);
    await saveOption(payload);
  };

  const categoryIdMap = useMemo(
    () => getCategoryIdMap(subcategoryData?.data?.categories, {}),
    [subcategoryData],
  );

  const headers = [
    {
      title: "Summary",
      dataIndex: "summary",
    },
    {
      title: "Category",
      dataIndex: "category_id",
      width: 240,
      render: (value, record) => {
        if (
          selectedField.rowData.id === record.id &&
          selectedField.colName === "Category" &&
          !selectedField.isHover
        )
          return (
            <CategoryDropdown
              autoFocus={true}
              isNewCategoryAllowed={true}
              onSelectOption={(option) => {
                onFinish({
                  id: record.id,
                  category: value,
                  category_id: option?.[option?.length - 1],
                  priority: record.priority,
                });
                setSelectedField({
                  rowData: {},
                  colName: "",
                });
              }}
              onCategoryCreation={(data) => {
                onFinish({
                  id: record.id,
                  category: value,
                  category_id: data?.data?.id,
                  priority: record.priority,
                });
                setSelectedField({
                  rowData: {},
                  colName: "",
                });
              }}
            />
          );
        if (!value)
          return (
            <Button
              icon={<PlusCircleOutlined />}
              type="dashed"
              onClick={() =>
                setSelectedField({
                  rowData: record,
                  colName: "Category",
                })
              }
            >
              Assign Category
            </Button>
          );
        return (
          <Row
            className="items-center"
            onMouseEnter={() =>
              setSelectedField({ rowData: record, colName: "Category", isHover: true })
            }
            onMouseLeave={() =>
              setSelectedField({ rowData: {}, colName: "", isHover: false })
            }
          >
            <div
              className="text-white text-[12px] font-semibold rounded py-[8px] px-[12px] leading-3"
              style={{
                backgroundColor: categoryIdMap?.[value]?.color
                  ? categoryIdMap?.[value]?.color
                  : isOptionSaveLoading
                  ? ""
                  : categoryIdMap?.[value]?.color || "#4385C2",
              }}
            >
              {categoryIdMap?.[value]?.color ? (
                <Tooltip
                  title={
                    getCategoryName?.(value, subcategoryData?.data?.categories) || ""
                  }
                >
                  {categoryIdMap?.[value]?.name}
                </Tooltip>
              ) : isOptionSaveLoading ? (
                <Spin />
              ) : (
                <Tooltip
                  title={
                    getCategoryName?.(value, subcategoryData?.data?.categories) || ""
                  }
                >
                  {categoryIdMap?.[value]?.name}
                </Tooltip>
              )}
            </div>
            {selectedField.rowData.id === record.id &&
              selectedField.colName === "Category" &&
              selectedField.isHover && (
                <EditFilled
                  className="ml-2"
                  onClick={() =>
                    setSelectedField({
                      rowData: record,
                      colName: "Category",
                      isHover: false,
                    })
                  }
                />
              )}
          </Row>
        );
      },
    },
    {
      title: "Priority",
      dataIndex: "priority",
      width: 240,
      render: (value, record) => {
        if (
          selectedField.rowData.id === record.id &&
          selectedField.colName === "Priority" &&
          !selectedField.isHover
        )
          return (
            <Select
              showSearch
              mode="multiple"
              clearIcon
              className="w-full"
              placeholder="Select Priorities"
              autoFocus
              defaultOpen
              onSelect={(value) => {
                onFinish({
                  id: record.id,
                  category: record.category,
                  priority: value,
                });
                setSelectedField({
                  rowData: {},
                  colName: "",
                });
              }}
              onBlur={() =>
                setSelectedField({
                  rowData: {},
                  colName: "",
                })
              }
            >
              {defaultPriorities?.map((priority) => (
                <Select.Option value={priority.key} key={`${priority.key} Priority`}>
                  {priority.text}
                </Select.Option>
              ))}
            </Select>
          );
        if (value === "unassigned")
          return (
            <Button
              icon={<PlusCircleOutlined />}
              type="dashed"
              onClick={() =>
                setSelectedField({
                  rowData: record,
                  colName: "Priority",
                })
              }
            >
              Assign Priority
            </Button>
          );
        return (
          <Row
            className="items-center"
            onMouseEnter={() =>
              setSelectedField({ rowData: record, colName: "Priority", isHover: true })
            }
            onMouseLeave={() =>
              setSelectedField({ rowData: {}, colName: "", isHover: false })
            }
          >
            {iconMap[value]} <div className="ml-2">{PRIORITY_PAIR[value] ?? value}</div>
            {selectedField.rowData.id === record.id &&
              selectedField.colName === "Priority" &&
              selectedField.isHover && (
                <EditFilled
                  className="ml-2"
                  onClick={() =>
                    setSelectedField({
                      rowData: record,
                      colName: "Priority",
                      isHover: false,
                    })
                  }
                />
              )}
          </Row>
        );
      },
    },
  ];

  if (isUserDataValidating)
    return (
      <Row className="h-screen justify-center items-center w-full">
        <Spin />
      </Row>
    );

  return (
    <>
      <Col className="m-4">
        <Typography.Text className="text-[22px] font-semibold m-0 p-0 mb-2">
          Meetings ({events?.length} entries)
        </Typography.Text>
        <CustomTable
          tableData={events || []}
          tableHeaders={headers}
          isTableLoading={isLoading}
        />
      </Col>
      <Row
        className="absolute bottom-0 border-t-black-500 w-full h-[60px] items-center justify-end"
        style={{
          boxShadow: "0px -4px 10px 0px rgba(0, 0, 0, 0.10)",
        }}
      >
        <Button className="m-2" onClick={handleOnboard} loading={isOnboarding}>
          Proceed and Continue later
        </Button>
      </Row>
    </>
  );
};

export default LoginCategories;
