import {
  Button,
  Collapse,
  Divider,
  Empty,
  InputNumber,
  message,
  Row,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { getDifferenceDate, zeroPad } from "@/utils/index.js";
import { useEffect, useMemo, useState } from "react";
import { setTimeout, clearTimeout } from "worker-timers";
import insightServices from "@/services/apiServices/insightServices/index.js";
import useLoading from "@/hooks/useLoading.js";
import useIsTabActive from "@/hooks/useIsTabActive.js";
import useLocalStorage from "@/hooks/useLocalStorage.js";

var id = null;
const NagToast = ({ timer, mutateEventReminderData }) => {
  const [updateNagLoadingType, setUpdateNagLoadingType] = useState("");
  const [snoozeMins, setSnoozeMins] = useLocalStorage("snooze_mins", 2);
  const isTabVisible = useIsTabActive();
  const {
    isVisible,
    setIsVisible,
    time,
    isRunning,
    events,
    currentEvent,
    isPlaying,
    playAudio,
    stopAudio,
  } = timer;

  const { executeAction: updateNag } = useLoading(
    async (payload, loadingType, newEvent) => {
      try {
        setUpdateNagLoadingType(loadingType);
        await insightServices.updateNag(newEvent?.id ?? currentEvent?.id, payload);
        if (
          typeof mutateEventReminderData === "function" &&
          payload.action !== "alarm_triggered"
        )
          await mutateEventReminderData?.();
      } catch (e) {
        message.error(
          typeof e?.response?.data?.err === typeof String()
            ? e.response.data.err
            : "Something went wrong. Please try again in some time.",
        );
      } finally {
        setUpdateNagLoadingType("");
        if (payload.action !== "alarm_triggered") {
          if (id) {
            clearTimeout(id);
            id = null;
          }
          stopAudio?.();
        }
      }
    },
  );

  const isTimerNearToPlay = useMemo(() => {
    return (
      isRunning &&
      currentEvent?.nag_time &&
      time.get("second") < 60 &&
      time.get("minute") === 0 &&
      time.get("hour") === 0
    );
  }, [isRunning, currentEvent?.nag_time, time]);

  const isTimerAboutToPlay = useMemo(() => {
    return (
      isRunning &&
      currentEvent?.nag_time &&
      time.get("second") === 0 &&
      time.get("minute") === 0 &&
      time.get("hour") === 0
    );
  }, [isRunning, currentEvent?.nag_time, time]);

  useEffect(() => {
    if (isTabVisible && !isTimerAboutToPlay && isPlaying) {
      stopAudio?.();
    }
    // eslint-disable-next-line
  }, [isTabVisible, isTimerAboutToPlay, isPlaying]);

  const playMusicSendEvent = async () => {
    playAudio?.();
    const payload = {
      action: "alarm_triggered",
      actor: "system",
    };
    await updateNag(payload);
  };

  const autoStopMusic = () => {
    id = setTimeout(async () => {
      const payload = {
        action: "alarm_stopped",
        actor: "system",
      };
      await updateNag(payload);
    }, 20000);
  };

  useEffect(() => {
    if (isVisible) {
      const fn = async () => {
        await playMusicSendEvent();
        await autoStopMusic();
      };

      if (isTimerAboutToPlay && !isPlaying) {
        fn();
      }
    }
    // eslint-disable-next-line
  }, [isTimerAboutToPlay, isPlaying, isVisible]);

  const getTime = (currentTime) => {
    return (
      "(" +
      dayjs(currentTime).format("hh") +
      ":" +
      dayjs(currentTime).format("mm") +
      " " +
      dayjs(currentTime).format("a") +
      ")"
    );
  };

  const handleClear = async () => {
    const payload = {
      action: "clear",
      actor: "user",
    };
    await updateNag(payload, "clear");
  };

  const Timer = (
    <Row>
      <Typography.Text className="font-medium tracking-[0.1px] mr-1">
        <Typography.Text className="text-primary text-[24px]">
          {zeroPad(dayjs(time)?.get("hour"), 2)}
        </Typography.Text>
        <Typography.Text className="text-primary text-[12px]">hr</Typography.Text>
      </Typography.Text>
      <Typography.Text className="font-medium tracking-[0.1px] mr-1">
        <Typography.Text className="text-primary text-[24px]">
          {zeroPad(dayjs(time)?.get("minute"), 2)}
        </Typography.Text>
        <Typography.Text className="text-primary text-[12px]">min</Typography.Text>
      </Typography.Text>
      <Typography.Text className="font-medium tracking-[0.1px]">
        <Typography.Text className="text-primary text-[24px]">
          {zeroPad(dayjs(time)?.get("second"), 2)}
        </Typography.Text>
        <Typography.Text className=" text-primary text-[12px]">sec</Typography.Text>
      </Typography.Text>
    </Row>
  );

  const pastAlarmReminders = useMemo(
    () =>
      events?.filter(
        (event) =>
          event?.nag_status === "alarm" &&
          new Date().getTime() >= new Date(event?.nag_time)?.getTime(),
      ) || [],
    [events],
  );

  const handleExtendTime = async (extendTime) => {
    const payload = {
      action: "snooze",
      snooze_by_mins: extendTime,
      actor: "user",
    };
    await updateNag(payload, `extend_by_${extendTime}`);
  };

  const handleRemindAgain = async (event) => {
    const [, minutes] = getDifferenceDate(dayjs(), dayjs(event.nag_time));
    const extendTime = minutes + 2;
    const payload = {
      action: "snooze",
      snooze_by_mins: extendTime,
      actor: "user",
    };
    await updateNag(payload, event.summary, event);
  };

  const nagAlarmEvents = useMemo(() => {
    return [
      {
        key: 0,
        label: "Past reminders (<15 mins) which are not acknowledged",
        children: pastAlarmReminders?.length ? (
          <div className="flex-col p-0">
            {pastAlarmReminders?.map((event) => (
              <div key={event.id} className="w-full p-[10px]">
                <Typography.Text className="text-[14px] leading-[22px] mr-1">
                  {getTime(event.nag_time)}
                </Typography.Text>
                <Typography.Text className="text-[14px] leading-[22px]">
                  {event.summary}
                </Typography.Text>
                <Button
                  type="link"
                  loading={updateNagLoadingType === event.summary}
                  className="m-0 p-0 ml-2"
                  onClick={() => handleRemindAgain(event)}
                >
                  Remind again
                </Button>
              </div>
            ))}
          </div>
        ) : (
          <Empty />
        ),
      },
    ];
    // eslint-disable-next-line
  }, [pastAlarmReminders, updateNagLoadingType]);

  if (!isVisible) return null;

  return (
    <div className="w-full">
      {(currentEvent?.nag_time ||
        (pastAlarmReminders && pastAlarmReminders.length > 0)) && (
        <Row
          className="justify-between items-center px-[30px] py-[6px] bg-white w-full mb-[30px]"
          style={{
            borderRadius: "10px",
            boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          {currentEvent?.nag_time && (
            <>
              <Row className="flex-col">
                <Typography.Text className="text-[#2A2E36] text-[14px] leading-[22px] mr-1">
                  {getTime(currentEvent?.nag_time)} {currentEvent?.summary}
                </Typography.Text>
                {Timer}
              </Row>
              <Row className="items-center">
                <Button
                  onClick={() => {
                    handleExtendTime(snoozeMins);
                  }}
                  loading={updateNagLoadingType === `extend_by_${snoozeMins}`}
                >
                  {`Snooze ${snoozeMins} mins`}
                </Button>
                <InputNumber
                  min={1}
                  max={60}
                  value={snoozeMins}
                  type="number"
                  className="w-[60px] ml-2"
                  onChange={(value) => {
                    setSnoozeMins(value);
                  }}
                />
                <Divider type="vertical" className="mx-4" />
                <Button
                  danger
                  onClick={handleClear}
                  loading={updateNagLoadingType === "clear"}
                >
                  Clear
                </Button>
                {!isTimerNearToPlay && (
                  <>
                    <Divider type="vertical" className="mx-4" />
                    <Button
                      type="primary"
                      className="bg-primary"
                      onClick={() => setIsVisible(false)}
                    >
                      Hide
                    </Button>
                  </>
                )}
              </Row>
            </>
          )}
          {pastAlarmReminders?.length > 0 && (
            <Collapse
              ghost
              defaultActiveKey={0}
              items={nagAlarmEvents}
              expandIconPosition={"end"}
              className="w-full mt-[10px]"
            />
          )}
        </Row>
      )}
    </div>
  );
};

export default NagToast;
