import { Button, Cascader, Divider } from "antd";
import { useMemo, useState } from "react";
import useSubcategory from "@/store/useSubcategory.js";
import { getCategoryIdMap, getNestedCategoryFromId } from "@/utils/index.js";
import { PlusOutlined } from "@ant-design/icons";
import CategoryModal from "@/components/CategoryModal/index.jsx";

const getCategoryItem = (item) => {
  return {
    id: item?.id,
    parent: item?.parent,
    color: item?.color,
    value: item?.id,
    label: item?.name,
    children: item?.childs?.map(getCategoryItem),
  };
};
const CategoryDropdown = ({
  defaultCategoryId,
  defaultValue,
  disabled,
  onSelectOption,
  isNewCategoryAllowed,
  onCategoryCreation,
  autoFocus,
}) => {
  const { data } = useSubcategory();
  const [isNewCategoryVisible, setIsNewCategoryVisible] = useState(false);

  const categoryValue = useMemo(() => {
    if (defaultValue && defaultValue.length !== 0) {
      return defaultValue;
    } else if (defaultCategoryId) {
      const arr = getNestedCategoryFromId(
        defaultCategoryId,
        getCategoryIdMap(data?.data?.categories, {}),
        [],
      );
      return arr.reverse();
    }
  }, [data, defaultCategoryId, defaultValue]);
  const onChange = (value, currentSelectedOptions) => {
    onSelectOption?.(value, currentSelectedOptions);
  };
  const filter = (inputValue, path) => {
    return path.some(
      (option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
    );
  };

  const menuItems = useMemo(() => {
    return (
      data?.data?.categories
        ?.filter((category) => category.name !== "unassigned")
        ?.map(getCategoryItem) || []
    );
  }, [data]);

  const dropdownRender = (menus) => (
    <div>
      {menus}
      {isNewCategoryAllowed && (
        <>
          <Divider style={{ margin: 0 }} />
          <Button
            icon={<PlusOutlined />}
            type="text"
            className="w-full"
            onClick={() => setIsNewCategoryVisible(true)}
          >
            Add category
          </Button>
        </>
      )}
    </div>
  );

  return (
    <div className="w-full">
      <Cascader
        options={menuItems}
        autoFocus={autoFocus}
        expandTrigger="hover"
        onChange={onChange}
        placeholder="Select Category"
        defaultValue={categoryValue}
        className="w-full"
        changeOnSelect={true}
        showSearch={{ filter }}
        disabled={disabled}
        dropdownRender={dropdownRender}
      />
      <CategoryModal
        isModalVisible={isNewCategoryVisible}
        onClose={() => {
          setIsNewCategoryVisible(false);
        }}
        onCategoryCreation={onCategoryCreation}
      />
    </div>
  );
};

export default CategoryDropdown;
