import { Avatar, Button, Divider, Row, Space, Typography } from "antd";
import useUser from "@/store/useUser.js";
import { useNavigate } from "react-router-dom";
import { CloseOutlined, LogoutOutlined, SettingOutlined } from "@ant-design/icons";
import { setAccessToken } from "@/utils/index.js";
import { googleLogout } from "@react-oauth/google";

const Profile = ({ onSubmit }) => {
  const { data: userData } = useUser();
  const navigate = useNavigate();
  const user = userData?.data;

  return (
    <div className="min-w-[380px] max-h-[674px] overflow-auto flex-col ant-dropdown-menu">
      <Row className="items-center justify-between p-[20px]">
        <Space>{""}</Space>
        <div>{user?.email}</div>
        <Button type="text" icon={<CloseOutlined />} onClick={onSubmit} />
      </Row>
      <Row className="flex-col items-center mb-[20px]">
        <Avatar className="m-0 p-0 flex items-center justify-center w-[80px] h-[80px] bg-primary text-3xl mb-[10px]">
          {user?.email?.[0]?.toUpperCase() || ""}
        </Avatar>
        <Typography.Text className="text-[#2A2E36] text-[20px] mb-[10px]">
          Hello
        </Typography.Text>
        <Button
          className="text-primary"
          icon={<SettingOutlined />}
          onClick={() => navigate("/profile")}
        >
          Profile Settings
        </Button>
      </Row>
      <Divider className="m-0 p-0" />
      <Button
        type="text"
        className="w-full flex items-center py-[20px] px-[40px] text-[14px] leading-[22px] text-primary"
        icon={<LogoutOutlined />}
        onClick={(e) => {
          googleLogout();
          e.preventDefault();
          navigate("/login");
          setAccessToken("");
          window.location.href = "/";
          localStorage.clear();
        }}
      >
        Log out
      </Button>
    </div>
  );
};

export default Profile;
