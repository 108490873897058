import { Button, message, Row, Typography } from "antd";
import CalendarBackground from "@/assets/calendar_background.svg";
import { CalendarOutlined } from "@ant-design/icons";
import { useGoogleLogin } from "@react-oauth/google";

const CalendarSignUp = ({ onClick }) => {
  const handleCalendarConnect = useGoogleLogin({
    onSuccess: (codeResponse) => onClick(codeResponse),
    onError: (error) => {
      if (typeof error?.error === "string") message.error("calendar" + " " + error.error);
      else console.error(error);
    },
    onNonOAuthError: (error) => console.error(error),
    scope: "https://www.googleapis.com/auth/calendar",
    flow: "auth-code",
  });
  return (
    <Row className="items-center justify-center text-center min-h-[100vh]">
      <div className="flex flex-col justify-center items-center">
        <Row className="mb-[20px]">
          <CalendarBackground />
        </Row>
        <Row className="mb-[60px] w-[50%]">
          <Typography.Text>
            Elevate your experience! To utilise the app fully and stay on top of your
            schedule, granting calendar access is necessary. Seamlessly sync events, set
            reminders, and optimise your time.
          </Typography.Text>
        </Row>
        <Row>
          <Button
            icon={<CalendarOutlined className="mr-2 hight-[32px] width-[32px]" />}
            className="flex justify-center items-center p-6"
            onClick={handleCalendarConnect}
          >
            Sync calendar
          </Button>
        </Row>
      </div>
    </Row>
  );
};

export default CalendarSignUp;
