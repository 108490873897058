import { Col, message, Row, Spin, Steps, Typography } from "antd";
import FiniteTimeLogo from "@/assets/new_finitetime_white_logo.svg";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import "@/pages/Login/index.css";
import SignUp from "@/pages/Login/SignUp.jsx";
import CalendarSignUp from "@/pages/Login/CalendarSignUp.jsx";
import { useNavigate } from "react-router-dom";
import { getAccessToken, setAccessToken } from "@/utils/index.js";
import LoginCategories from "@/pages/Login/LoginCategories.jsx";
import useLoading from "@/hooks/useLoading.js";
import userService from "@/services/apiServices/userService/index.js";
import apiClient, { setAxiosInstanceHeaders } from "@/services/apiClient/index.js";
import useUser from "@/store/useUser.js";

const Login = () => {
  const accessToken = getAccessToken();
  const {
    data: userData,
    mutate: mutateUserData,
    isLoading: isUserInfoLoading,
    isValidating: isUserInfoValidating,
  } = useUser();

  let navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);

  useLayoutEffect(() => {
    if (accessToken && userData?.data?.gcal_connected && userData?.data?.onboarded) {
      navigate("/overview");
    }
  }, [accessToken, userData, navigate]);

  useEffect(() => {
    if (!accessToken && !userData?.data?.gcal_connected) setCurrentStep(0);
    else if (accessToken && !userData?.data?.gcal_connected) setCurrentStep(1);
    else if (accessToken && userData?.data?.gcal_connected && !userData?.data?.onboarded)
      setCurrentStep(2);
  }, [userData, accessToken, navigate]);

  const { executeAction: fetchUserData, loading: isUserLoading } = useLoading(
    async (authResponse) => {
      try {
        const data = await userService.getUserToken(authResponse?.code);
        if (data?.data?.error) {
          message.error(data?.data?.error);
          return;
        }
        setAccessToken(data?.data?.token);
        setAxiosInstanceHeaders(apiClient);
        message.success("Login Successfully.");
        if (data?.data?.gcal_connected && data?.data?.onboarded) {
          navigate("/overview");
        }
      } catch {
        setAccessToken("");
        localStorage.clear();
      }
    },
  );

  const { executeAction: fetchCalendarData, loading: isCalendarLoading } = useLoading(
    async (authResponse) => {
      try {
        const currentCalendarData = await userService.getUserCalendarDetails(
          authResponse?.code,
        );
        if (currentCalendarData?.data) {
          await mutateUserData();
          message.success("Calendar Connected Successfully.");
        } else if (currentCalendarData?.data?.error) {
          message.error("Something went wrong. Please try again in some time.");
        }
      } catch {
        message.error("Something went wrong. Please try again in some time.");
      }
    },
  );

  const loginSteps = useMemo(() => {
    const defaultLoginStepItems = [
      {
        key: 0,
        title: (
          <div className="text-white text-[16px] leading-[32px]">
            Login to your google account
          </div>
        ),
        content: <SignUp onClick={fetchUserData} />,
      },
      {
        key: 1,
        title: (
          <div className="text-white text-[16px] leading-[32px]">Sync your Calendar</div>
        ),
        content: <CalendarSignUp onClick={fetchCalendarData} />,
      },
      {
        key: 2,
        title: (
          <div className="text-white text-[16px] leading-[32px]">
            Categories and tag your calendar entries
          </div>
        ),
        content: <LoginCategories />,
      },
    ];
    return defaultLoginStepItems.map((step) => {
      if (step.key < currentStep) {
        return {
          ...step,
          status: "finish",
        };
      }
      if (step.key === currentStep) {
        return {
          ...step,
          status: "process",
        };
      }
      if (step.key > currentStep) {
        return {
          ...step,
          status: "wait",
        };
      }
    });
    /* eslint-disable-next-line */
  }, [currentStep]);

  if (isUserLoading || isCalendarLoading || isUserInfoLoading || isUserInfoValidating)
    return (
      <Row className="h-screen justify-center items-center w-full">
        <Spin />
      </Row>
    );

  return (
    <Row className="w-full h-screen">
      <Col span={8} className="bg-dark-indigo">
        <Col className="mx-[60px] my-[40px]">
          <Row className="mb-[60px]">
            <FiniteTimeLogo className="w-[300px] h-[100px] text-white" />
          </Row>
          <Row className="mb-[32px]">
            <Typography.Text className="text-white font-medium text-[32px]">
              Get Started
            </Typography.Text>
          </Row>
          <Row className="h-[224px]">
            <Steps current={currentStep} direction="vertical" items={loginSteps} />
          </Row>
        </Col>
      </Col>
      <Col span={16}>{loginSteps[currentStep].content ?? ""}</Col>
    </Row>
  );
};

export default Login;
