import { Table, Skeleton } from "antd";

const SkeletonTable = ({ columns, rowCount = 10 }) => (
  <Table
    rowKey="key"
    className="w-full"
    pagination={false}
    dataSource={[...Array(rowCount)].map((_, index) => ({
      key: `key${index}`,
    }))}
    columns={columns.map((column) => ({
      ...column,
      render: function renderPlaceholder() {
        return (
          <div data-testid="table-skeleton-list">
            <Skeleton key={column.dataIndex} title paragraph={false} />
          </div>
        );
      },
    }))}
  />
);

export default SkeletonTable;
