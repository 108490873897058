import { Avatar, Button, Input, message, Modal, Row, Typography } from "antd";
import { ArrowRightOutlined, LinkOutlined, MailOutlined } from "@ant-design/icons";
import useUser from "@/store/useUser.js";
import { useEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import useLoading from "@/hooks/useLoading.js";
import userService from "@/services/apiServices/userService/index.js";
import { defaultPriorities } from "@/mocks/index.js";

const ConnectAppInbox = () => {
  const [modalType, setModalType] = useState(false);
  const { data: userData, mutate: mutateUserData } = useUser();
  const user = userData?.data;
  const [priorityMap, setPriorityMap] = useState([]);

  useEffect(() => {
    if (modalType) setPriorityMap(user?.settings?.gmail_label_priority_mapping || []);
  }, [user, modalType]);

  const { executeAction: fetchMailData, loading: isMailDataLoading } = useLoading(
    async (authResponse) => {
      try {
        const currentCalendarData = await userService.getUserMailDetails(
          authResponse?.code,
        );
        if (currentCalendarData?.data) {
          await mutateUserData();
          message.success("Gmail Connected Successfully.");
        } else if (currentCalendarData?.data?.error) {
          message.error("Something went wrong. Please try again in some time.");
        }
      } catch {
        message.error("Something went wrong. Please try again in some time.");
      }
    },
  );

  const { executeAction: saveUserPrioritySetting, loading: isSaveUserSettingLoading } =
    useLoading(async () => {
      try {
        await userService.updateSettings({
          gmail_label_priority_mapping: priorityMap,
        });
        await mutateUserData();
        message.success("Priority labels Updated Successfully.");
      } catch {
        message.error("Something went wrong. Please try again in some time.");
      }
    });

  const handleCalendarConnect = useGoogleLogin({
    onSuccess: (codeResponse) => fetchMailData(codeResponse),
    onError: (error) => {
      if (typeof error?.error === "string") message.error("calendar" + " " + error.error);
      else console.error(error);
    },
    onNonOAuthError: (error) => console.error(error),
    scope:
      "https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email openid",
    flow: "auth-code",
  });

  const handlePriorityLabelChange = (label, priority) => {
    let currentPriorityMap = [...priorityMap];
    const index = currentPriorityMap?.findIndex((item) => item?.priority === priority);

    if (index < 0) currentPriorityMap.push({ label: label, priority: priority });
    else currentPriorityMap[index] = { label: label, priority: priority };

    setPriorityMap([...currentPriorityMap]);
  };

  const getDefaultPriorityValue = (priority) => {
    const index = priorityMap?.findIndex((item) => item?.priority === priority?.key);
    if (index < -1) return null;
    return priorityMap[index]?.label;
  };

  return (
    <>
      <Row className="items-center justify-between">
        <Row>
          <div className="mr-[20px]">
            <MailOutlined className="text-[48px] text-primary" />
          </div>
          <Row className="flex flex-col">
            <Typography.Text className="text-[14px] leading-[22px] font-semibold">
              Gmail app
            </Typography.Text>
            <Typography.Text className="text-[14px] text-[#606369] leading-[22px] font-normal">
              Connect gmail and sync your active inbox entries with Finitetime
            </Typography.Text>
          </Row>
        </Row>
        <Button
          onClick={() => {
            if (!user?.connected_apps?.gmail) handleCalendarConnect();
            else setModalType(true);
          }}
          loading={isMailDataLoading}
        >
          {!user?.connected_apps?.gmail ? "Connect" : "Configure"}
        </Button>
      </Row>
      <Modal
        title="Connecting apps"
        open={modalType}
        onCancel={() => {
          setModalType(false);
          setPriorityMap([]);
        }}
        onOk={() => {}}
        centered
        footer={[
          <Button
            key="save"
            type="primary"
            className="bg-primary"
            onClick={saveUserPrioritySetting}
            loading={isSaveUserSettingLoading}
          >
            Save
          </Button>,
        ]}
        width={650}
        destroyOnClose
      >
        <Row className="flex-col">
          <Row className="items-center justify-center mb-[20px]">
            <Avatar className="w-[48px] h-[48px] mr-[30px] flex items-center justify-center bg-primary text-3xl">
              {user?.email?.[0]?.toUpperCase()}
            </Avatar>
            <LinkOutlined className="text-[22px]" />
            <div className="ml-[30px]">
              <MailOutlined className="text-[48px] text-primary" />
            </div>
          </Row>
          <Typography.Text className="text-[16px] font-medium leading-[22px]">
            Map your Active inbox priority label settings
          </Typography.Text>
          <Row className="w-full mt-[20px] justify-center">
            {defaultPriorities
              ?.filter((item) => item?.key !== "unassigned")
              ?.map((priority) => (
                <Row className="mb-5 items-center w-full" key={priority.key}>
                  <Input
                    defaultValue={getDefaultPriorityValue(priority)}
                    placeholder={`Label ${priority.text} priority`}
                    onChange={(e) => {
                      handlePriorityLabelChange(e.target.value, priority.key);
                    }}
                    className="mr-2 w-[30%]"
                  />
                  <ArrowRightOutlined />
                  <Typography.Text className="text-[14px] leading-[22px] ml-2">
                    {priority.text}
                  </Typography.Text>
                </Row>
              ))}
          </Row>
        </Row>
      </Modal>
    </>
  );
};

export default ConnectAppInbox;
