import { create } from "zustand";

const useCalendar = create((set) => ({
  calendarIds: [],
  subCalendarIds: [],
  setCalendarIds: (newCalendars) => {
    set({
      ...newCalendars,
    });
  },
}));

export default useCalendar;
