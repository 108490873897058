import { useState } from "react";

const useLoading = (action) => {
  const [loading, setLoading] = useState(false);
  const executeAction = async (...args) => {
    setLoading(true);
    try {
      return await action(...args);
    } catch (error) {
      throw new Error(error?.message ?? "Oops something went wrong");
    } finally {
      setLoading(false);
    }
  };
  return { executeAction, loading };
};
export default useLoading;
