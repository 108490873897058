import { measureTextWidth } from "@ant-design/plots";

const dummyBarChartData = [
  {
    time: "2019-03",
    value: 350,
    type: "uv",
  },
  {
    time: "2019-04",
    value: 900,
    type: "uv",
  },
  {
    time: "2019-05",
    value: 300,
    type: "uv",
  },
  {
    time: "2019-06",
    value: 450,
    type: "uv",
  },
  {
    time: "2019-07",
    value: 470,
    type: "uv",
  },
  {
    time: "2019-03",
    value: 220,
    type: "bill",
  },
  {
    time: "2019-04",
    value: 300,
    type: "bill",
  },
  {
    time: "2019-05",
    value: 250,
    type: "bill",
  },
  {
    time: "2019-06",
    value: 220,
    type: "bill",
  },
  {
    time: "2019-07",
    value: 362,
    type: "bill",
  },
];

const dummyPieChartData = [
  {
    type: "分类一",
    value: 27,
  },
  {
    type: "分类二",
    value: 25,
  },
  {
    type: "分类三",
    value: 18,
  },
  {
    type: "分类四",
    value: 15,
  },
  {
    type: "分类五",
    value: 10,
  },
  {
    type: "其他",
    value: 5,
  },
];

export function renderStatistic(containerWidth, text, style) {
  const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
  const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

  let scale = 1;

  if (containerWidth < textWidth) {
    scale = Math.min(
      Math.sqrt(
        Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))),
      ),
      1,
    );
  }

  const textStyleStr = `width:${containerWidth}px;`;
  return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
    scale < 1 ? 1 : "inherit"
  };">${text}</div>`;
}

export const dummyPieChartConfig = {
  width: 426,
  height: 194,
  data: dummyPieChartData,
  angleField: "value",
  colorField: "type",
  radius: 1,
  innerRadius: 0.8,
  meta: {
    value: {
      formatter: (v) => `${v} ¥`,
    },
  },
  label: {
    type: "inner",
    offset: "-50%",
    style: {
      textAlign: "center",
      fontSize: 8,
    },
    autoRotate: false,
    content: "{value}",
  },
  interactions: [
    {
      type: "element-selected",
    },
    {
      type: "element-active",
    },
    {
      type: "pie-statistic-active",
    },
  ],
  statistic: {
    title: {
      offsetY: -4,
      customHtml: (container, view, datum) => {
        const { width, height } = container.getBoundingClientRect();
        const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
        const text = datum ? datum.type : "总计";
        return renderStatistic(d, text, {
          fontSize: 28,
        });
      },
    },
    content: {
      offsetY: 4,
      style: {
        fontSize: "32px",
      },
      customHtml: (container, view, datum, data) => {
        const { width } = container.getBoundingClientRect();
        const text = datum
          ? `¥ ${datum.value}`
          : `¥ ${data.reduce((r, d) => r + d.value, 0)}`;
        return renderStatistic(width, text, {
          fontSize: 32,
        });
      },
    },
  },
};

export const dummyBarChartConfig = {
  width: 426,
  height: 194,
  data: [dummyBarChartData, []],
  xField: "time",
  yField: ["value"],
  geometryOptions: [
    {
      geometry: "column",
      isStack: true,
      isPercent: true,
      seriesField: "type",
      width: "480px",
    },
  ],
  legend: {
    position: "right",
  },
};

export const defaultPriorities = [
  {
    key: "very-high",
    text: "Very high",
  },
  {
    key: "high",
    text: "High",
  },
  {
    key: "medium",
    text: "Medium",
  },
  {
    key: "low",
    text: "Low",
  },
  {
    key: "unassigned",
    text: "Unassigned",
  },
];

export const defaultTimeForRespond = [
  {
    key: "auto-start-of-day",
    text: "7 am",
  },
  {
    key: "auto-middle-of-day",
    text: "12 pm",
  },
  {
    key: "auto-end-of-day",
    text: "7 pm",
  },
  {
    key: "manual",
    text: "recent",
  },
];

export const defaultGroups = [
  {
    key: "group_empty",
    text: "Do Not Group",
  },
  {
    key: "group_priority",
    text: "Priority",
  },
  {
    key: "group_dates",
    text: "Date",
  },
];

export const defaultMailChartGroups = [
  {
    key: "checkpoint",
    text: "Time split",
  },
  {
    key: "priority",
    text: "Priority split",
  },
];

export const defaultMailChartMetrics = [
  {
    key: "inbox_mail",
    text: "Mails in my inbox",
  },
  {
    key: "threads",
    text: "Responded mail threads",
  },
  {
    key: "overdue_threads",
    text: "Overdue responded mail threads",
  },
  {
    key: "overdue_total_threads",
    text: "Overdue total mail threads",
  },
  {
    key: "mail_respond_time",
    text: "Mail respond time",
  },
  {
    key: "overdue_mail_respond_time",
    text: "Overdue mail respond time",
  },
];

export const defaultTaskChartMetrics = [
  {
    key: "overall_stats",
    text: "Number of tasks",
  },
  {
    key: "overdue_stats",
    text: "Number of overdue tasks",
  },
  {
    key: "overdue_days_stats",
    text: "Overdue tasks time",
  },
];

export const defaultChartAggregateFunction = [
  {
    key: "mean",
    text: "Mean",
  },
  {
    key: "percentiles",
    text: "Percentiles",
  },
];

export const defaultChartPercentiles = [
  {
    key: 50,
    text: "50%",
  },
  {
    key: 80,
    text: "80%",
  },
  {
    key: 90,
    text: "90%",
  },
  {
    key: 95,
    text: "95%",
  },
];

export const defaultTaskChartFilter = [
  {
    key: "overdue_tasks",
    text: "Only show over due tasks",
  },
  {
    key: "incomplete_tasks",
    text: "Show all incomplete tasks",
  },
];

export const defaultTaskViews = [
  {
    key: "due_date",
    text: "View by due dates",
  },
  {
    key: "schedule_date",
    text: "View by scheduled dates",
  },
];

export const defaultFrequencyRange = [
  {
    key: "week",
    text: "Week",
  },
  {
    key: "day",
    text: "Day",
  },
];

export const defaultJiraTypes = [
  {
    key: "Epic",
    text: "Epic",
  },
  {
    key: "Story",
    text: "Story",
  },
  {
    key: "Sub-task",
    text: "Sub Task",
  },
  {
    key: "Bug",
    text: "Bug",
  },
];

export const defaultProjectSource = [
  {
    key: "jira",
    text: "Jira",
  },
];

export const defaultDueDates = [
  "Past Due",
  "Due Today",
  "Due Tomorrow",
  "Due next 7 days",
  "Due after 7 days",
];

export const defaultScheduleTodoStatus = [
  {
    key: "not_scheduled",
    text: "Not Scheduled",
  },
  {
    key: "scheduled",
    text: "Scheduled",
  },
];

export const defaultCompletionTodoStatus = [
  {
    key: "completed",
    text: "Completed",
  },
  {
    key: "incomplete",
    text: "Not Completed",
  },
];

export const defaultFrequencies = [
  {
    key: "multi",
    text: "Yes",
  },
  {
    key: "single",
    text: "No",
  },
];

export const defaultScheduleUseCases = [
  {
    key: "Zoom",
    text: "Zoom",
  },
  {
    key: "Google Meet",
    text: "Google Meet",
  },
  {
    key: "In Person",
    text: "In Person",
  },
  {
    key: "Other",
    text: "Other",
  },
];
export const defaultReminders = [
  {
    day: "Sun",
    slots: ["05:30", "18:00"],
  },
  {
    day: "Mon",
    slots: ["05:30", "18:00"],
  },
  {
    day: "Tue",
    slots: [],
  },
  {
    day: "Wed",
    slots: [],
  },
  {
    day: "Thu",
    slots: [],
  },
  {
    day: "Fri",
    slots: [],
  },
  {
    day: "Sat",
    slots: [],
  },
];

export const events = [
  {
    id: 0,
    title: "Board meeting",
    start: new Date(2023, 8, 27, 8, 0, 0),
    end: new Date(2023, 8, 27, 13, 0, 0),
    resourceId: 1,
  },
  {
    id: 1,
    title: "MS training",
    start: new Date(2023, 8, 27, 14, 0, 0),
    end: new Date(2023, 8, 27, 16, 30, 0),
    resourceId: 2,
  },
  {
    id: 2,
    title: "Team lead meeting",
    start: new Date(2023, 8, 27, 6, 30, 0),
    end: new Date(2023, 8, 27, 8, 30, 0),
    resourceId: [2, 3],
  },
  {
    id: 11,
    title: "Birthday Party",
    start: new Date(2023, 8, 27, 8, 30, 0),
    end: new Date(2023, 8, 27, 10, 30, 0),
    resourceId: [4],
  },
];

export const resourceMap = [
  { resourceId: 1, resourceTitle: "Timeline" },
  { resourceId: 2, resourceTitle: "nbaitest5@gmail.com" },
  { resourceId: 3, resourceTitle: "himanshu@nbai-inc.com" },
  { resourceId: 4, resourceTitle: "himanshu.work46@nbai-inc.com" },
];

export const cascaderOptions = [
  {
    value: "zhejiang",
    label: "Zhejiang",
    children: [
      {
        value: "hangzhou",
        label: "Hangzhou",
        children: [
          {
            value: "xihu",
            label: "West Lake",
          },
          {
            value: "xiasha",
            label: "Xia Sha",
          },
        ],
      },
    ],
  },
  {
    value: "jiangsu",
    label: "Jiangsu",
    children: [
      {
        value: "nanjing",
        label: "Nanjing",
        children: [
          {
            value: "zhonghuamen",
            label: "Zhong Hua men",
          },
        ],
      },
    ],
  },
];

export const categoryTreeData = [
  {
    title: "parent 1",
    key: "0-0",
    children: [
      {
        title: "parent 1-0",
        key: "0-0-0",
        children: [
          {
            title: "leaf",
            key: "0-0-0-0",
          },
          {
            title: "leaf",
            key: "0-0-0-1",
          },
        ],
      },
      {
        title: "parent 1-1",
        key: "0-0-1",
      },
    ],
  },
];

export const colorItems = [
  "#FF5733",
  "#FFD700",
  "#00FF7F",
  "#4682B4",
  "#800080",
  "#FFA500",
  "#00CED1",
  "#9932CC",
  "#008000",
  "#8B4513",
  "#1E90FF",
  "#FF1493",
  "#2E8B57",
];
