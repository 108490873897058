import { Button, Col, Divider, Input, message, Row, TimePicker, Typography } from "antd";
import dayjs from "dayjs";
import useTimer from "@/store/useTimer.js";
import { zeroPad } from "@/utils/index.js";
import useLoading from "@/hooks/useLoading.js";
import insightServices from "@/services/apiServices/insightServices/index.js";
import eventBus from "@/utils/eventBus.js";
import { EVENT_BUS_EVENTS } from "@/constants/index.js";
import Union from "@/assets/union.svg";
import { DeleteOutlined } from "@ant-design/icons";
import { useState } from "react";
const format = "HH:mm";

const TimerHeader = () => {
  const text = useTimer((store) => store.text);
  const category = useTimer((store) => store.category);
  const priority = useTimer((store) => store.priority);
  const startTime = useTimer((store) => store.startTime);
  const time = useTimer((store) => store.time);
  const endTime = useTimer((store) => store.endTime);

  const stop = useTimer((store) => store.stop);
  const setCategory = useTimer((store) => store.setCategory);
  const setPriority = useTimer((store) => store.setPriority);
  const setText = useTimer((store) => store.setText);
  const isTodoTimerRunning = useTimer((store) => store.isTodoTimerRunning);
  const setIsTodoTimerRunning = useTimer((store) => store.setIsTodoTimerRunning);
  const timerTodo = useTimer((store) => store.timerTodo);
  const setTimerTodo = useTimer((store) => store.setTimerTodo);
  const subtasks = useTimer((store) => store.subtasks);
  const setSubtasks = useTimer((store) => store.setSubtasks);
  const [subtaskText, setSubtaskText] = useState("");

  const resetValues = () => {
    stop();
    setCategory("");
    setPriority("");
    setText("");
    setSubtaskText("");
    setSubtasks([]);
    setIsTodoTimerRunning(false);
    setTimerTodo({});
  };

  const { executeAction: handleUpdateTodo, loading: isUpdateTodoLoading } = useLoading(
    async () => {
      try {
        const payload = {
          id: timerTodo.id,
          title: timerTodo.title,
          ...((timerTodo.duration || timerTodo.durationSlots) && {
            duration:
              timerTodo.durationSlots === "custom"
                ? timerTodo.duration
                : timerTodo.durationSlots,
          }),
          ...(timerTodo.category_id && { category_id: timerTodo.category_id }),
          ...(timerTodo.zone_id && { zone_id: timerTodo.zone_id }),
          ...(timerTodo.priority && { priority: timerTodo.priority }),
          subtasks:
            subtasks?.map((task) => ({
              ...task,
              start_time: task.start_time ? dayjs(task.start_time)?.format() : "",
              end_time: task.end_time ? dayjs(task.end_time)?.format() : "",
            })) || [],
        };
        await insightServices.updateTodo(payload);
        await insightServices.markTodoComplete(payload);
        eventBus.emit(EVENT_BUS_EVENTS.REVALIDATE_TODOS, {});
      } catch (e) {
        message.error(
          typeof e?.response?.data?.msg === typeof String()
            ? e.response.data.msg
            : "Something went wrong. Please try again in some time.",
        );
      }
    },
  );

  const {
    executeAction: handleCreateTimelineEntry,
    loading: isCreateTimelineEntryLoading,
  } = useLoading(async () => {
    if (!text || text?.length === 0) {
      message.error("Timer title required");
      return;
    }
    try {
      const payload = {
        summary: text,
        start_time: dayjs(startTime).format("YYYY-MM-DDTHH:mm:ss[Z]"),
        end_time: dayjs(endTime).format("YYYY-MM-DDTHH:mm:ss[Z]"),
        category: category,
        priority: priority || "unassigned",
        subtasks:
          subtasks?.map((task) => ({
            ...task,
            start_time: task.start_time ? dayjs(task.start_time)?.format() : "",
            end_time: task.end_time ? dayjs(task.end_time)?.format() : "",
          })) || [],
      };
      await insightServices.createTimeline(payload);
      eventBus.emit(EVENT_BUS_EVENTS.REVALIDATE_TIMELINE_EVENTS, {});
      eventBus.emit(EVENT_BUS_EVENTS.REVALIDATE_TODO, {});
      message.success("Timer event created Successfully.");
    } catch (error) {
      message.error(
        typeof error?.response?.data?.msg === "string"
          ? error?.response?.data?.msg
          : "Something went wrong. Please try again in some time.",
      );
    } finally {
      resetValues();
    }
  });

  const handleAddSubtask = () => {
    if (!subtaskText) {
      message.info("Name required");
      return;
    }
    const currentSubtasks = [...subtasks];
    const currentText = subtaskText;
    currentSubtasks.push({
      title: currentText,
      start_time: "",
      end_time: "",
      id: new Date().getTime(),
    });
    setSubtasks(currentSubtasks);
    setSubtaskText("");
  };

  const handleDeleteSubtask = (id) => {
    let currentSubtasks = [...subtasks];
    currentSubtasks = currentSubtasks.filter((task) => task.id !== id);
    setSubtasks(currentSubtasks);
  };

  const handleTaskTimeChange = (id, time) => {
    let currentSubtasks = [...subtasks];
    const [startTime, endTime] = time;
    currentSubtasks = currentSubtasks.map((task) => {
      if (task.id == id) {
        return {
          ...task,
          start_time: dayjs(startTime),
          end_time: dayjs(endTime),
        };
      }
      return task;
    });
    setSubtasks(currentSubtasks);
  };

  const handleSubtaskStart = (id) => {
    let currentSubtasks = [...subtasks];
    currentSubtasks = currentSubtasks.map((task) => {
      if (task.id == id) {
        return {
          ...task,
          start_time: dayjs(),
        };
      }
      return task;
    });
    setSubtasks(currentSubtasks);
  };

  const handleSubtaskStop = (id) => {
    let currentSubtasks = [...subtasks];
    currentSubtasks = currentSubtasks.map((task) => {
      if (task.id == id) {
        return {
          ...task,
          end_time: dayjs(),
        };
      }
      return task;
    });
    setSubtasks(currentSubtasks);
  };

  const getDefaultTime = (task) => {
    let time = [];
    if (task.start_time) time.push(dayjs(task.start_time, format));
    if (task.end_time) time.push(dayjs(task.end_time, format));
    return time;
  };

  const handleTimerStop = async () => {
    if (isTodoTimerRunning) await handleUpdateTodo();
    await handleCreateTimelineEntry();
  };
  return (
    <Row
      className="items-center px-[30px] py-[10px] bg-white w-full mb-[30px]"
      style={{
        borderRadius: "10px",
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
      }}
    >
      <Row className="items-center justify-between w-full">
        <Typography.Text className="text-[16px] font-semibold leading-[24px]">
          Timer {text} running
        </Typography.Text>
        <Row className="items-center">
          <Typography.Text className="font-medium tracking-[0.1px] mr-1">
            <Typography.Text className="text-primary text-[24px]">
              {zeroPad(dayjs(time)?.get("hour"), 2)}
            </Typography.Text>
            <Typography.Text className="text-primary text-[12px]">hr</Typography.Text>
          </Typography.Text>
          <Typography.Text className="font-medium tracking-[0.1px] mr-1">
            <Typography.Text className="text-primary text-[24px]">
              {zeroPad(dayjs(time)?.get("minute"), 2)}
            </Typography.Text>
            <Typography.Text className="text-primary text-[12px]">min</Typography.Text>
          </Typography.Text>
          <Typography.Text className="font-medium tracking-[0.1px]">
            <Typography.Text className="text-primary text-[24px]">
              {zeroPad(dayjs(time)?.get("second"), 2)}
            </Typography.Text>
            <Typography.Text className=" text-primary text-[12px]">sec</Typography.Text>
          </Typography.Text>
          <Button
            type="primary"
            className="bg-primary ml-[30px]"
            onClick={handleTimerStop}
            loading={isCreateTimelineEntryLoading || isUpdateTodoLoading}
          >
            Stop
          </Button>
        </Row>
      </Row>
      <Divider className="my-[20px]" />
      <Row className="flex-col w-full">
        <Row className="items-center mb-[20px]">
          <Union className="fill-black" />
          <Typography.Text className="ml-2">Subtask</Typography.Text>
        </Row>
        {subtasks?.map((task, index) => (
          <Row key={task.id} className="items-center justify-between mb-[10px]">
            <Row className="items-center">
              <div
                className="h-[16px] w-[16px] flex rounded justify-center items-center text-center text-[10px] mr-2  text-[#606369]"
                style={{
                  borderRadius: "32px",
                  border:
                    "1px solid var(--character-disabled-placeholder-25, rgba(0, 0, 0, 0.25))",
                }}
              >
                {index + 1}
              </div>
              <Typography.Text className="text-[14px] leading-[22px]">
                {task.title}
              </Typography.Text>
            </Row>
            <Row className="items-center">
              <TimePicker.RangePicker
                format={format}
                value={getDefaultTime(task)}
                onChange={(value) => handleTaskTimeChange(task.id, value)}
                className="mr-4"
              />
              {!task.start_time && (
                <Button
                  type="primary"
                  className="bg-primary"
                  onClick={() => handleSubtaskStart(task.id)}
                >
                  Start
                </Button>
              )}
              {task.start_time && !task.end_time && (
                <Button
                  type="primary"
                  className="bg-primary"
                  onClick={() => handleSubtaskStop(task.id)}
                >
                  Stop
                </Button>
              )}
              <DeleteOutlined
                className="ml-4"
                onClick={() => handleDeleteSubtask(task.id)}
              />
            </Row>
          </Row>
        ))}
        <Row className="justify-between w-full my-[10px]">
          <Col span={20}>
            <Input
              value={subtaskText}
              placeholder="Type and hit enter to add new subtask"
              onChange={(e) => setSubtaskText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleAddSubtask();
                }
              }}
            />
          </Col>
          <Col span={4} className="flex flex-row-reverse">
            <Button onClick={handleAddSubtask} type="primary" className="bg-primary ml-2">
              Add new subtask
            </Button>
          </Col>
        </Row>
      </Row>
    </Row>
  );
};

export default TimerHeader;
