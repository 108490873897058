import { Button, Empty, Row, Spin, Tooltip, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { monthL, week } from "@/constants/index.js";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);

const DaySlots = ({
  slotsData,
  isLoading,
  onChange,
  slotRange,
  slotType,
  slotsSide,
  isRecommended,
  timeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
}) => {
  const [selectedSlotValue, setSelectedSlotValue] = useState();
  const [deSelectedSlots, setDeSelectedSlots] = useState([]);
  const [multiSelectedSlots, setMultiSelectedSlots] = useState([]);

  useEffect(() => {
    setDeSelectedSlots([]);
  }, [slotRange]);

  useEffect(() => {
    if (slotType === "multiselect" && isRecommended) {
      const selectedSlots = [];
      slotsData?.forEach((slot) => {
        if (slot?.is_selected) {
          selectedSlots.push(slot);
        }
      });
      setMultiSelectedSlots(selectedSlots);
    }
  }, [slotsData, slotType, isRecommended]);

  const slotsList = useMemo(() => {
    if (slotsData) {
      let slotData = [];

      if (isRecommended) slotData = slotsData;
      else if (slotRange === "next_week") slotData = slotsData?.data?.next_week?.slots;
      else if (slotRange === "after_next_week")
        slotData = slotsData?.data?.after_next_week?.slots;
      else if (slotRange === "current_week")
        slotData = slotsData?.data?.current_week?.slots;
      else if (slotRange === "custom") slotData = slotsData?.data?.slots;

      const list = {};
      slotData?.forEach((slot) => {
        const day = dayjs(slot.start_time).get("day");
        const date = dayjs(slot.start_time).get("date");
        const month = dayjs(slot.start_time).get("month");
        const key = week[day] + ", " + monthL[month] + " " + date;
        const time =
          dayjs(slot.start_time).tz(timeZone).format("hh") +
          ":" +
          dayjs(slot.start_time).tz(timeZone).format("mm") +
          " " +
          dayjs(slot.start_time).tz(timeZone).format("a");
        if (!list[key]) {
          list[key] = [];
        }
        list[key].push({
          value: time,
          key: {
            start_time: slot.start_time,
            end_time: slot.end_time,
          },
          overlapping_events: slot.overlapping_events || [],
          is_selected: slot.is_selected,
          flex: slot.flex,
          ...(slot.summary && { summary: slot.summary }),
          ...(slot.id && { id: slot.id }),
        });
      });
      return list;
    }
    return {};
  }, [slotsData, slotRange, isRecommended, timeZone]);

  const hasDeselectedSlot = (currentSlot) => {
    let currentDeselectedSlots = [...deSelectedSlots];
    return currentDeselectedSlots?.some(
      (slot) =>
        slot.start_time === currentSlot.start_time &&
        slot.end_time === currentSlot.end_time,
    );
  };

  const handleDeselectedSlot = (currentSlot) => {
    let currentDeselectedSlots = [...deSelectedSlots];
    currentDeselectedSlots = currentDeselectedSlots.filter(
      (slot) =>
        slot.start_time !== currentSlot.start_time &&
        slot.end_time !== currentSlot.end_time,
    );
    if (currentDeselectedSlots.length === deSelectedSlots.length) {
      currentDeselectedSlots = [...currentDeselectedSlots, currentSlot];
    }
    setDeSelectedSlots(currentDeselectedSlots);
  };

  const hasMultiSelectedSlot = (currentSlot) => {
    let currentMultiSelectedSlots = [...multiSelectedSlots];
    return currentMultiSelectedSlots?.some(
      (slot) =>
        slot.start_time === currentSlot.start_time &&
        slot.end_time === currentSlot.end_time,
    );
  };

  const handleMultiSelectedSlot = (currentSlot) => {
    let currentMultiSelectedSlots = [...multiSelectedSlots];
    currentMultiSelectedSlots = currentMultiSelectedSlots.filter(
      (slot) =>
        slot.start_time !== currentSlot.start_time &&
        slot.end_time !== currentSlot.end_time,
    );
    if (currentMultiSelectedSlots.length === multiSelectedSlots.length) {
      currentSlot = {
        ...currentSlot,
        flex: false,
      };
      currentMultiSelectedSlots = [...currentMultiSelectedSlots, currentSlot];
    }
    setMultiSelectedSlots([...currentMultiSelectedSlots]);
  };

  async function updateGuestLink(newSelectedSlots) {
    onChange?.(newSelectedSlots);
  }

  useEffect(() => {
    let slotData = [];

    if (slotRange === "next_week") slotData = slotsData?.data?.next_week?.slots;
    else if (slotRange === "after_next_week")
      slotData = slotsData?.data?.after_next_week?.slots;
    else if (slotRange === "current_week")
      slotData = slotsData?.data?.current_week?.slots;

    const newSelectedSlots = slotData?.filter((slot) => !hasDeselectedSlot(slot));

    updateGuestLink(newSelectedSlots);
    // eslint-disable-next-line
  }, [deSelectedSlots, slotsData]);

  useEffect(() => {
    updateGuestLink(multiSelectedSlots);
    // eslint-disable-next-line
  }, [multiSelectedSlots, slotsData]);

  useEffect(() => {
    return () => {
      setSelectedSlotValue();
      setDeSelectedSlots([]);
      setMultiSelectedSlots([]);
    };
  }, []);

  const isCategoryBudgetEmpty = useMemo(() => {
    let isCurrentCategoryBudgetEmpty = false;
    if (slotRange === "next_week")
      isCurrentCategoryBudgetEmpty = slotsData?.data?.next_week?.is_budget_exhaused;
    else if (slotRange === "after_next_week")
      isCurrentCategoryBudgetEmpty = slotsData?.data?.after_next_week?.is_budget_exhaused;
    else if (slotRange === "current_week")
      isCurrentCategoryBudgetEmpty = slotsData?.data?.current_week?.is_budget_exhaused;

    return isCurrentCategoryBudgetEmpty;
  }, [slotRange, slotsData]);

  return (
    <>
      {isLoading && (
        <div className="flex items-center h-full h-[200px] justify-center w-full my-[20px]">
          <Spin />
        </div>
      )}
      {Object.values(slotsList)?.length === 0 && !isLoading && !isCategoryBudgetEmpty && (
        <Empty />
      )}
      {isCategoryBudgetEmpty && (
        <Empty description="Category budget has been exhausted!" />
      )}
      {Object.entries(slotsList)?.map(([time, slots]) => (
        <Row key={time} className="flex-col mb-[20px]">
          <Typography.Text className="text-[12px] mb-[8px] font-semibold leading-[20px]">
            {time}
          </Typography.Text>
          <Row>
            {slotType === "select" && (
              <>
                {slots?.map((slotTime, index) => (
                  <Tooltip
                    key={index}
                    placement="topLeft"
                    title={
                      slotTime?.overlapping_events?.length > 0 && slotsSide === "user"
                        ? `Conflict event ${slotTime?.overlapping_events?.[0]?.summary}`
                        : ""
                    }
                  >
                    <Button
                      type={
                        slotTime.key.start_time == selectedSlotValue?.start_time &&
                        slotTime.key.end_time == selectedSlotValue?.end_time
                          ? "primary"
                          : "default"
                      }
                      ghost={
                        slotTime.key.start_time == selectedSlotValue?.start_time &&
                        slotTime.key.end_time == selectedSlotValue?.end_time
                      }
                      key={index}
                      className={slotTime.summary ? "m-1 inline-flex" : "m-1 w-[100px]"}
                      onClick={() => {
                        setSelectedSlotValue(slotTime.key);
                        slotTime?.id
                          ? onChange({ id: slotTime?.id, time: slotTime?.key })
                          : onChange(slotTime?.key);
                      }}
                      style={
                        slotTime?.overlapping_events?.length > 0 &&
                        !(
                          slotTime.key.start_time == selectedSlotValue?.start_time &&
                          slotTime.key.end_time == selectedSlotValue?.end_time
                        ) &&
                        slotsSide === "user"
                          ? {
                              borderColor: "orange",
                              color: "orange",
                            }
                          : {}
                      }
                    >
                      {" "}
                      {slotTime.summary
                        ? slotTime.summary + " (" + slotTime.value + ") "
                        : slotTime.value}
                    </Button>
                  </Tooltip>
                ))}{" "}
              </>
            )}
            {slotType === "deselect" && (
              <>
                {slots?.map((slotTime, index) => (
                  <Tooltip
                    key={index}
                    placement="topLeft"
                    title={
                      slotTime?.overlapping_events?.length > 0 && slotsSide === "user"
                        ? `Conflict event ${slotTime?.overlapping_events?.[0]?.summary}`
                        : ""
                    }
                  >
                    <Button
                      type={!hasDeselectedSlot(slotTime.key) ? "primary" : "default"}
                      ghost={!hasDeselectedSlot(slotTime.key)}
                      key={index}
                      className="m-1 w-[100px]"
                      onClick={() => handleDeselectedSlot(slotTime.key)}
                      style={
                        slotTime?.overlapping_events?.length > 0 &&
                        !hasDeselectedSlot(slotTime.key) &&
                        slotsSide === "user"
                          ? {
                              borderColor: "orange",
                              color: "orange",
                            }
                          : {}
                      }
                    >
                      {" "}
                      {slotTime.value}
                    </Button>
                  </Tooltip>
                ))}
              </>
            )}
            {slotType === "multiselect" && (
              <>
                {slots?.map((slotTime, index) => (
                  <Tooltip
                    key={JSON.stringify(slotTime.key)}
                    placement="topLeft"
                    title={
                      slotTime?.overlapping_events?.length > 0 && slotsSide === "user"
                        ? `Conflict event ${slotTime?.overlapping_events?.[0]?.summary}`
                        : ""
                    }
                  >
                    <Button
                      type={hasMultiSelectedSlot(slotTime.key) ? "primary" : "default"}
                      ghost={hasMultiSelectedSlot(slotTime.key)}
                      key={index}
                      className="m-1 w-[100px]"
                      onClick={() => handleMultiSelectedSlot(slotTime.key)}
                      style={
                        slotTime?.overlapping_events?.length > 0 &&
                        !hasMultiSelectedSlot(slotTime.key) &&
                        slotsSide === "user"
                          ? {
                              borderColor: "orange",
                              color: "orange",
                            }
                          : {}
                      }
                    >
                      {" "}
                      {slotTime.value}
                    </Button>
                  </Tooltip>
                ))}
              </>
            )}
          </Row>
        </Row>
      ))}
    </>
  );
};

export default DaySlots;
