import { Avatar, Button, Col, Input, message, Modal, Row, Typography } from "antd";
import Timely from "@/assets/timely.svg";
import { useState } from "react";
import { LinkOutlined } from "@ant-design/icons";
import useLoading from "@/hooks/useLoading.js";
import insightServices from "@/services/apiServices/insightServices/index.js";
import useUser from "@/store/useUser.js";
import LinkOff from "@/assets/link_off.svg";
import useLocalStorage from "@/hooks/useLocalStorage.js";

const ConnectTimingApp = () => {
  const [modalType, setModalType] = useState("");
  const [timelyApiKey, setTimelyApiKey] = useState("");
  const { data: userData, mutate: mutateUserData } = useUser();
  const [, setIsTimingAppVisible] = useLocalStorage("timing", false);

  const user = userData?.data;

  const { executeAction: handleTimelyConnect, loading: isTimelyAppConnectLoading } =
    useLoading(async () => {
      try {
        if (!timelyApiKey || timelyApiKey?.length === 0) {
          message.error("Please fill the api key");
          return;
        }
        await insightServices.connectTimely(timelyApiKey);
        await mutateUserData();
        message.success("Timing app connected successfully.");
      } catch (e) {
        message.error(
          typeof e?.response?.data?.error === typeof String()
            ? e.response.data.error
            : "Something went wrong. Please try again in some time.",
        );
      } finally {
        setModalType("");
        setTimelyApiKey("");
      }
    });
  const { executeAction: handleTimelyDisconnect, loading: isTimelyAppDisconnectLoading } =
    useLoading(async () => {
      try {
        await insightServices.disconnectTimely();
        await mutateUserData();
        message.success("Timing app disconnected successfully.");
        setIsTimingAppVisible(false);
      } catch (e) {
        message.error(
          typeof e?.response?.data?.error === typeof String()
            ? e.response.data.error
            : "Something went wrong. Please try again in some time.",
        );
      } finally {
        setModalType("");
      }
    });

  return (
    <div>
      <Row className="items-center justify-between">
        <Row>
          <div className="mr-[20px]">
            <Timely />
          </div>
          <Row className="flex flex-col">
            <Typography.Text className="text-[14px] leading-[22px] font-semibold">
              Timing app
            </Typography.Text>
            <Typography.Text className="text-[14px] text-[#606369] leading-[22px] font-normal">
              Connect accounts and sync your time entries and tasks with Finitetime
            </Typography.Text>
          </Row>
        </Row>
        <Button
          onClick={() => {
            if (!user?.is_timing_connected) setModalType("Timely");
            else setModalType("Timely_Disconnect");
          }}
        >
          {!user?.is_timing_connected ? "Connect" : "Disconnect"}
        </Button>
      </Row>
      <Modal
        title="Connecting apps"
        open={modalType === "Timely"}
        onCancel={() => {
          setModalType("");
        }}
        onOk={() => {}}
        centered
        footer={[]}
        width={650}
        destroyOnClose
      >
        <Row className="flex-col">
          <Row className="items-center justify-center mb-[20px]">
            <Avatar className="w-[48px] h-[48px] mr-[30px] flex items-center justify-center bg-primary text-3xl">
              {user?.email?.[0]?.toUpperCase()}
            </Avatar>
            <LinkOutlined className="text-[22px]" />
            <div className="ml-[30px]">
              <Timely />
            </div>
          </Row>
          <Typography.Text className="text-[16px] font-medium leading-[22px]">
            Connect accounts and sync your time entries and tasks with Finitetime
          </Typography.Text>
          <Row className="items-center mt-[8px] mb-[20px]">
            <Col span={19}>
              <Input
                value={timelyApiKey}
                onChange={(e) => setTimelyApiKey(e.target.value)}
                placeholder="Add API key"
              />
            </Col>
            <Col span={4}>
              <Button
                type="primary"
                className="bg-primaryDark ml-[8px] w-full"
                onClick={handleTimelyConnect}
                loading={isTimelyAppConnectLoading}
              >
                Connect
              </Button>
            </Col>
          </Row>
          <Typography.Text className="text-[#606369] text-[14px] leading-[22px] mb-[20px]">
            By connecting to Timing, App can access information about related calendar
            activity. They may also share info with FiniteTime about your calendar account
            and tasks.
          </Typography.Text>
          <Typography.Text className="text-[#606369] text-[14px] leading-[22px]">
            You can disconnect anytime in your Finitetime settings.
          </Typography.Text>
        </Row>
      </Modal>
      <Modal
        title="Connecting apps"
        open={modalType === "Timely_Disconnect"}
        onCancel={() => {
          setModalType("");
        }}
        onOk={() => {}}
        centered
        footer={[
          <Button onClick={() => setModalType("")} key="cancel">
            Cancel
          </Button>,
          <Button
            type="primary"
            danger={true}
            onClick={handleTimelyDisconnect}
            key="disconnect"
            loading={isTimelyAppDisconnectLoading}
          >
            Disconnect
          </Button>,
        ]}
        width={650}
        destroyOnClose
      >
        <Row className="flex-col">
          <Row className="items-center justify-center mb-[20px]">
            <Avatar className="w-[48px] h-[48px] mr-[30px] flex items-center justify-center bg-primary text-3xl">
              {user?.email?.[0]?.toUpperCase()}
            </Avatar>
            <LinkOff className="text-[22px]" />
            <div className="ml-[30px]">
              <Timely />
            </div>
          </Row>

          <Typography.Text className="text-[16px] font-medium leading-[22px]">
            Disconnecting Timing will remove all future entries from TimingApp
          </Typography.Text>
          <Typography.Text className="text-[#606369] text-[14px] leading-[22px] mb-[20px]">
            You will not be able to view timing entries after disconnecting Timing. It
            will not affect your existing timeline entries.
          </Typography.Text>
          <Typography.Text className="text-[#606369] text-[14px] leading-[22px]">
            You can reconnect anytime in your Finitetime settings.
          </Typography.Text>
        </Row>
      </Modal>
    </div>
  );
};

export default ConnectTimingApp;
