import useSWR from "swr";
import insightServices from "@/services/apiServices/insightServices/index.js";
import { getAccessToken, setAccessToken } from "@/utils/index.js";
import { useNavigate } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";

const useZones = () => {
  const accessToken = getAccessToken();
  const navigate = useNavigate();

  const data = useSWR(
    accessToken || accessToken?.length > 0 ? `fetchZoneData` : null,
    insightServices.getZones,
  );

  if (accessToken && data?.error && Object.keys(data?.error)?.length > 0) {
    googleLogout();
    navigate("/login");
    setAccessToken("");
    window.location.href = "/";
    localStorage.clear();
  }

  return data;
};

export default useZones;
