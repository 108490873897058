import { clearInterval, setInterval } from "worker-timers";
import { create } from "zustand";
import dayjs from "dayjs";
let id;

const useTimer = create((set) => ({
  text: "",
  category: null,
  priority: null,
  startTime: dayjs(),
  endTime: dayjs(),
  time: dayjs().set("hour", 0).set("minute", 0).set("second", 0),
  isRunning: false,
  isPlaying: false,
  subtasks: [],
  isTodoTimerRunning: false,
  timerTodo: {},
  start: (newTime) => {
    if (id) {
      clearInterval(id);
      id = undefined;
    } else {
      set({
        startTime: dayjs(),
      });
    }
    if (newTime) {
      set(() => ({
        time: dayjs(newTime)
          .set("hour", dayjs(newTime).get("hours") - dayjs().get("hours"))
          .set("minute", dayjs(newTime).get("minutes") - dayjs().get("minutes"))
          .set("second", dayjs(newTime).get("seconds") - dayjs().get("seconds")),
        isRunning: true,
        endTime: dayjs(),
      }));
      if (!id) {
        id = setInterval(() => {
          set((state) => ({
            time:
              state.time.get("second") === 0 &&
              state.time.get("minute") === 0 &&
              state.time.get("hour") === 0
                ? state.time
                : dayjs(newTime)
                    .set("hour", dayjs(newTime).get("hours") - dayjs().get("hours"))
                    .set("minute", dayjs(newTime).get("minutes") - dayjs().get("minutes"))
                    .set(
                      "second",
                      dayjs(newTime).get("seconds") - dayjs().get("seconds"),
                    ),
            endTime: dayjs(),
          }));
        }, 1000);
      }
    } else {
      if (id) {
        clearInterval(id);
        id = undefined;
      }
      set({
        startTime: dayjs(),
        endTime: dayjs(),
        time: dayjs().set("hour", 0).set("minute", 0).set("second", 0),
        isRunning: true,
      });
      id = setInterval(() => {
        set((state) => ({
          time: dayjs()
            .set("hour", dayjs().get("hours") - dayjs(state.startTime).get("hours"))
            .set("minute", dayjs().get("minutes") - dayjs(state.startTime).get("minutes"))
            .set(
              "second",
              dayjs().get("seconds") - dayjs(state.startTime).get("seconds"),
            ),
          endTime: dayjs(),
        }));
      }, 1000);
    }
  },
  stop: () => {
    if (id) {
      clearInterval(id);
      id = undefined;
    }
    set({
      text: "",
      category: null,
      priority: null,
      startTime: dayjs(),
      endTime: dayjs(),
      time: dayjs().set("hour", 0).set("minute", 0).set("second", 0),
      isRunning: false,
      isTodoTimerRunning: false,
    });
  },
  setText: (value) => {
    set({
      text: value,
    });
  },
  setCategory: (value) => {
    set({
      category: value,
    });
  },
  setPriority: (value) => {
    set({
      priority: value,
    });
  },
  setSubtasks: (value) => {
    set({
      subtasks: value,
    });
  },
  setIsTodoTimerRunning: (value) => {
    set({
      isTodoTimerRunning: value,
    });
  },
  setTimerTodo: (value) => {
    set({
      timerTodo: { ...value },
    });
  },
  setIsPlaying: (value) => {
    set({
      isPlaying: value,
    });
  },
}));

export default useTimer;
