import { Button, Divider, Select } from "antd";
import useZones from "@/store/useZones.js";
import { PlusOutlined } from "@ant-design/icons";
import Zones from "@/components/Zones/index.jsx";
import { useMemo, useState } from "react";
import useUser from "@/store/useUser.js";

const ZoneDropdown = ({ value, onChange, isMultiSelect, isNewZoneAllowed }) => {
  const { data: zoneData } = useZones();
  const { data: userData } = useUser();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const sortedZones = useMemo(() => {
    if (!zoneData || !userData) return [];
    const currentZones = zoneData?.data?.zones || [];
    const referenceZones = userData?.data?.settings?.zone_ids || [];
    currentZones?.sort((a, b) => {
      return referenceZones?.indexOf(a?.id) - referenceZones?.indexOf(b?.id);
    });
    return currentZones;
  }, [userData, zoneData]);

  const dropdownRender = (menus) => (
    <div>
      {menus}
      {isNewZoneAllowed && (
        <>
          <Divider style={{ margin: 0 }} />
          <Button
            icon={<PlusOutlined />}
            type="text"
            className="w-full"
            onClick={() => setIsModalVisible(true)}
          >
            Add Zone
          </Button>
        </>
      )}
    </div>
  );

  return (
    <>
      <Select
        showSearch
        allowClear
        mode={isMultiSelect ? "multiple" : "default"}
        className="w-full"
        placeholder="Select Zone"
        value={value ?? undefined}
        onClear={() => onChange(isMultiSelect ? [] : null)}
        onChange={(value) => onChange(value)}
        filterOption={(inputValue, option) =>
          option?.children?.toString()?.toLowerCase()?.includes(inputValue?.toLowerCase())
        }
        dropdownRender={dropdownRender}
      >
        {sortedZones?.map((zone) => (
          <Select.Option key={zone?.id} value={zone?.id}>
            {zone?.title}
          </Select.Option>
        ))}
      </Select>
      {isModalVisible && <Zones isOnlyModal={isModalVisible} />}
    </>
  );
};

export default ZoneDropdown;
