import {
  Button,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  message,
  Row,
  Space,
  Spin,
  theme,
  TimePicker,
} from "antd";
import { CopyOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { cloneElement, useEffect, useState } from "react";
import dayjs from "dayjs";
import useSWR from "swr";
import insightServices from "@/services/apiServices/insightServices/index.js";
import useLoading from "@/hooks/useLoading.js";

const format = "HH:mm";
const { useToken } = theme;

const getDefaultReminders = (reminderData) => {
  return reminderData?.map((newReminder) => {
    return {
      ...newReminder,
      disabled: newReminder.slots.length === 0,
    };
  });
};
const ReminderModal = () => {
  const {
    data: reminderData,
    isLoading,
    mutate,
  } = useSWR("fetchReminderData", insightServices.getReminders);

  const [reminders, setReminders] = useState([]);
  const [cloneDays, setCloneDays] = useState([]);
  const [cloneDayIndex, setCloneDayIndex] = useState(-1);
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const { token } = useToken();

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: "none",
  };

  useEffect(() => {
    setReminders(getDefaultReminders(reminderData?.data?.reminders) || []);
  }, [reminderData]);

  const handleAddReminder = (reminder) => {
    const filteredReminders = reminders.map((item) => {
      if (reminder.day === item.day) {
        if (item.slots[item.slots.length - 1].length > 0) item.slots.push("");
      }
      return item;
    });
    setReminders(filteredReminders);
    handleSaveReminders(filteredReminders);
  };
  const handleDeleteReminder = (newReminder, newSlot) => {
    const filteredReminders = reminders.map((reminder) => {
      if (newReminder.day === reminder.day) {
        if (reminder.slots.length === 1) {
          reminder.slots = [""];
          reminder.disabled = !reminder.disabled;
          return reminder;
        } else
          reminder.slots = reminder.slots.filter(
            (currentSlot) => currentSlot !== newSlot,
          );
      }
      return reminder;
    });
    setReminders(filteredReminders);
    handleSaveReminders(filteredReminders);
  };

  const handleTimeSelect = (timeValue, selectedSlot, selectedReminder) => {
    const filteredReminders = reminders.map((reminder) => {
      if (selectedReminder.day === reminder.day) {
        if (reminder.slots.length === 0) {
          if (timeValue) reminder.slots.push(dayjs(timeValue).format(format));
          else reminder.slots.push("");
        } else {
          if (timeValue) reminder.slots[selectedSlot] = dayjs(timeValue).format(format);
          else reminder.slots[selectedSlot] = "";
        }
      }
      return reminder;
    });
    setReminders(filteredReminders);
    handleSaveReminders(filteredReminders);
  };

  const handleSelectReminder = (newReminder) => {
    const filteredReminders = reminders.map((reminder) => {
      if (newReminder.day === reminder.day) {
        reminder.disabled = !reminder.disabled;
      }
      return reminder;
    });
    setReminders(filteredReminders);
    handleSaveReminders(filteredReminders);
  };

  const { executeAction: handleSaveReminders } = useLoading(async (reminders) => {
    try {
      const empty = (element) => element === "";
      const payload = reminders.map((reminder) => {
        const newReminder = { ...reminder };
        if (newReminder.disabled) newReminder.slots = [];
        else if (!newReminder.disabled && newReminder.slots.length === 0)
          newReminder.slots = [""];
        delete newReminder.disabled;
        return newReminder;
      });
      let error = "";
      payload.forEach((reminder) => {
        if (reminder.slots.some(empty)) {
          error = "Please fill the details of selected reminder";
          return;
        }
      });
      if (error) {
        // message.warning(error);
        return;
      }
      await insightServices.saveReminders({
        reminders: payload,
      });
      await mutate();
      message.success("Reminder Saved Successfully.");
    } catch (e) {
      message.error(
        typeof e?.response?.data?.err === typeof String()
          ? e.response.data.err
          : "Something went wrong. Please try again in some time.",
      );
    }
  });

  const handleDaySelect = (day) => {
    if (!cloneDays.includes(day)) setCloneDays([...cloneDays, day]);
    else setCloneDays(cloneDays.filter((currentDay) => currentDay !== day));
  };

  const dayItems = days.map((day) => {
    return {
      key: day,
      label: (
        <div onClick={() => handleDaySelect(day)}>
          <Checkbox checked={cloneDays.includes(day)} className="mr-2" /> {day}
        </div>
      ),
    };
  });

  const resetDays = () => {
    setCloneDays([]);
    setCloneDayIndex(-1);
  };

  const handleCloneDays = (currentReminder) => {
    const selectedDays = [...cloneDays];

    const filteredReminders = reminders.map((reminder) => {
      if (selectedDays.includes(reminder.day)) {
        return {
          ...currentReminder,
          day: reminder.day,
        };
      }
      return reminder;
    });
    setReminders(filteredReminders);
    handleSaveReminders(filteredReminders);
    resetDays();
  };

  return (
    <div className="w-full">
      <div className="min-h-[514px] min-w-[400px]">
        {isLoading ? (
          <Row className="items-center justify-center h-[514px] w-[400px]">
            <Spin />
          </Row>
        ) : (
          <div>
            {reminders?.map((reminder, index) => (
              <div key={index}>
                <Row className="w-full pb-[10px] flex-nowrap">
                  <Col className="w-[90px] mt-1">
                    <Checkbox
                      className="mr-[8px]"
                      onClick={() => handleSelectReminder(reminder)}
                      checked={!reminder.disabled}
                    />
                    <span className="text-[14px] mr-4">{reminder.day}</span>
                  </Col>
                  <Col className="mr-[42px]">
                    {reminder.slots.length ? (
                      reminder.slots.map((slot, index) => (
                        <Row key={slot} className="mb-2 items-center">
                          {slot.length !== 0 ? (
                            <TimePicker
                              format={format}
                              className="mr-2 w-[320px]"
                              defaultValue={dayjs(slot, format)}
                              onChange={(value) =>
                                handleTimeSelect(value, index, reminder)
                              }
                              disabled={reminder.disabled}
                            />
                          ) : (
                            <TimePicker
                              format={format}
                              className="mr-2 w-[320px]"
                              onChange={(value) =>
                                handleTimeSelect(value, index, reminder)
                              }
                              disabled={reminder.disabled}
                            />
                          )}
                          <Button
                            type="text"
                            icon={<DeleteOutlined />}
                            onClick={() => handleDeleteReminder(reminder, slot)}
                            disabled={reminder.disabled}
                          />
                        </Row>
                      ))
                    ) : (
                      <Row className="mb-2 items-center">
                        <TimePicker
                          format={format}
                          className="mr-2 w-[320px]"
                          disabled={reminder.disabled}
                          onChange={(value) => handleTimeSelect(value, index, reminder)}
                        />
                        <Button
                          type="text"
                          icon={<DeleteOutlined />}
                          onClick={() => handleDeleteReminder(reminder)}
                          disabled={reminder.disabled}
                        />
                      </Row>
                    )}
                  </Col>
                  <Col>
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={() => handleAddReminder(reminder)}
                      disabled={reminder.disabled}
                    />
                  </Col>
                  <Col key={index}>
                    <Dropdown
                      menu={{
                        items: dayItems,
                      }}
                      open={index === cloneDayIndex}
                      onOpenChange={() => resetDays()}
                      trigger={["click"]}
                      placement="bottomRight"
                      disabled={reminder.disabled}
                      className="m-0 p-0"
                      dropdownRender={(menu) => (
                        <div style={contentStyle}>
                          {cloneElement(menu, { style: menuStyle })}
                          <Divider style={{ margin: 0 }} />
                          <Space style={{ padding: 8 }}>
                            <Button
                              type="primary"
                              className="bg-primary"
                              onClick={() => handleCloneDays(reminder)}
                            >
                              Clone Day
                            </Button>
                          </Space>
                        </div>
                      )}
                    >
                      <Button
                        type="text"
                        icon={<CopyOutlined />}
                        onClick={() => setCloneDayIndex(index)}
                        disabled={reminder.disabled}
                      />
                    </Dropdown>
                  </Col>
                </Row>
                <div className="pb-[20px]" />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReminderModal;
