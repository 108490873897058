export const EVENT_BUS_EVENTS = {
  REVALIDATE_ALL_EVENTS_CHART: "REVALIDATE_ALL_EVENTS_CHART",
  REVALIDATE_TIMELINE_EVENTS: "REVALIDATE_TIMELINE_EVENTS",
  REVALIDATE_TODOS: "REVALIDATE_TODOS",
  REVALIDATE_EVENT_REMINDERS: "REVALIDATE_EVENT_REMINDERS",
  REVALIDATE_TODO: "REVALIDATE_TODO",
  SELECT_GOAL: "SELECT_GOAL",
};

const PRIORITY_KEYS = {
  VERY_HIGH: "very-high",
  HIGH: "high",
  MEDIUM: "medium",
  LOW: "low",
  UN_ASSIGNED: "unassigned",
};

export const PRIORITY_PAIR = {
  [PRIORITY_KEYS.VERY_HIGH]: "Very high",
  [PRIORITY_KEYS.HIGH]: "High",
  [PRIORITY_KEYS.MEDIUM]: "Medium",
  [PRIORITY_KEYS.LOW]: "Low",
  [PRIORITY_KEYS.UN_ASSIGNED]: "Unassigned",
};

export const CLIENT_ID = {
  PRODUCTION: "841906613629-munmhs9q1sss0ubmvq7n44amv8ea4fuq.apps.googleusercontent.com",
  STAGING: "802823916741-idk1c1p0a3m1sdi72pfinaille99ajt8.apps.googleusercontent.com",
};

export const monthL = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const monthS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
export const week = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const weekS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const durationSlots = [
  {
    key: 2,
    value: "2 mins",
  },
  {
    key: 5,
    value: "5 mins",
  },
  {
    key: 10,
    value: "10 mins",
  },
  {
    key: 15,
    value: "15 mins",
  },
  {
    key: 30,
    value: "30 mins",
  },
  {
    key: 45,
    value: "45 mins",
  },
  {
    key: 60,
    value: "60 mins",
  },
  {
    key: "custom",
    value: "Custom",
  },
];
